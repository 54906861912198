import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastTypes } from '../../../models/ToastTypes';
import useToast from '../../../hooks/use-toast';
import Config from '../../../Config';
import FilterHeader, { AdvancedFilter } from '../FilterHeader';
import TabFilter from '../TabFilter';
import { QuotationStatus, GetQuotation, GetQuotationParameters } from '../../../models/Quotation';
import DataTable from 'react-data-table-component';
import TableStyles from '../../../styles/tableStyles';
import { Icon } from '../../../stories/dune/atoms/Icon';
import moment from 'moment';
import { SelectStatus } from '../../forms/SelectStatus';
import { Button } from '../../../stories/dune/atoms/Button';
import { GlobalParameter } from '../../../models/GlobalParameter';

interface ListQuotationsProps {
  defaultStatus?: string;
  forcedFilters?: AdvancedFilter[];
  quotationType: 'Quotation' | 'Contract';
}

const ListQuotations = React.memo((props: ListQuotationsProps) => {
  // #region const init

  // #region generic
  const { t } = useTranslation();
  const { addToast } = useToast();

  const [isLoading, setIsLoading] = useState(true);

  const history = useNavigate();

  const defaulTab =
    QuotationStatus.CREATED +
    ';' +
    QuotationStatus.UPDATED +
    ';' +
    QuotationStatus.SENT +
    ';' +
    QuotationStatus.GENERATED +
    ';' +
    QuotationStatus.IN_ERROR;

  const [filtersAdvanced, setFiltersAdvanced] = useState<AdvancedFilter[]>(props.forcedFilters ?? []);
  const [filtersTab, setFiltersTab] = useState<string[]>(defaulTab.split(';'));
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState(20);
  const [sortColumn, setSortColumn] = useState('createdAt');
  const [sortDirection, setSortDirection] = useState('desc');
  const [totalRows, setTotalRows] = useState(0);
  const [totalResource, setTotalResource] = useState(0);

  const [siteTimeZone, setSiteTimeZone] = useState<string>('Europe/Paris');
  useEffect(() => {
    const globalParametersSite: GlobalParameter[] = JSON.parse(localStorage.getItem('globalParameters') ?? '[]');
    setSiteTimeZone(globalParametersSite.find((x) => x.label === 'siteTimeZone')?.value ?? 'Europe/Paris');
  }, []);

  // #endregion generic

  // #region specific

  const [filteredItems, setFilteredItems] = useState<GetQuotation[]>([]);

  const fetchItems = (
    page: number,
    newPerPage: number,
    newSortColumn: string = sortColumn,
    newSortDirection: string = sortDirection,
    TabFilter: string[] = filtersTab,
    AdvancedFilters: AdvancedFilter[] = filtersAdvanced,
  ) => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');

    const url =
      orgid +
      '/quotation/get?page=' +
      page +
      '&limit=' +
      newPerPage +
      '&sortBy=' +
      newSortColumn +
      '&orderBy=' +
      newSortDirection;

    setCurrentPage(page);
    setIsLoading(true);
    setFilteredItems([]);

    if (newSortColumn != sortColumn) {
      setSortColumn(newSortColumn);
    }

    if (newSortDirection != sortDirection) {
      setSortDirection(newSortDirection);
    }

    axios
      .post(
        Config.getApiExtranetUrl(url),
        { ...getFiltersToSend(TabFilter, AdvancedFilters), quotationType: props.quotationType },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(async (res) => {
        setTotalRows(res.data.meta.total);
        setTotalResource(res.data.meta.total);
        setFilteredItems(res.data.content.items);

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  };

  function getFiltersToSend(tabFilters: string[], advancedFilters: AdvancedFilter[]) {
    const toSend: GetQuotationParameters = {};

    if (tabFilters?.length > 0) toSend.status = tabFilters;

    if (advancedFilters.length > 0) {
      advancedFilters.map((x) => {
        if (x.filterType === 'search') toSend.search = x.filterData;
        if (x.filterType === 'buildingSite') toSend.buildingSiteId = x.filterData?.value;
        if (x.filterType === 'customer') toSend.customerId = x.filterData?.value;
        if (x.filterType === 'externalReference') toSend.externalReference = x.filterData;
        if (x.filterType === 'number') toSend.quotationNumber = x.filterData;
      });
    }

    return toSend;
  }

  const tabFilterOptions = [
    { tabLabel: 'Tous', tabLabelTranslate: t('common.all') },
    {
      tabLabel: defaulTab,
      tabLabelTranslate: t('status.InProgress'),
    },
    { tabLabel: QuotationStatus.VALIDATED, tabLabelTranslate: t('status.' + QuotationStatus.VALIDATED) },
    { tabLabel: QuotationStatus.REFUSED, tabLabelTranslate: t('status.' + QuotationStatus.REFUSED) },
    { tabLabel: QuotationStatus.EXPIRED, tabLabelTranslate: t('status.' + QuotationStatus.EXPIRED) },
  ];

  const seeDetails = (row: GetQuotation) => {
    // TODO INTEGRATION : gestion popup si dans sous-fenetre ?
    const url = props.quotationType.toLowerCase() + 's';
    return `/${url}/${row.id}/update`;
  };

  const columns: any[] = [
    {
      name: t('common.number'),
      selector: (row: GetQuotation) => row.quotationNumber,
      cell: (row: GetQuotation) => (
        <Link to={seeDetails(row)}>
          <div className='column' data-tag='allowRowEvents'>
            <div data-tag='allowRowEvents'>
              <strong className='caption2medium' data-tag='allowRowEvents'>
                {row.quotationNumber + (row.quotationSubject ? ' - ' + row.quotationSubject : '')}
              </strong>
            </div>
            <div data-tag='allowRowEvents'>
              <i className='caption2medium' data-tag='allowRowEvents'>
                {row.externalReference}
              </i>
            </div>
          </div>
        </Link>
      ),
      sortable: true,
      right: false,
      id: 'quotationNumber',
    },
    {
      name: t('common.customer'),
      selector: (row: GetQuotation) => row.customer?.label,
      cell: (row: GetQuotation) => (
        <Link to={seeDetails(row)}>
          <div className='column' data-tag='allowRowEvents'>
            <div data-tag='allowRowEvents'>
              <strong className='caption2medium' data-tag='allowRowEvents'>
                {row.customer?.label}
              </strong>
            </div>
            <div data-tag='allowRowEvents'>
              <i className='caption2medium' data-tag='allowRowEvents'>
                {row.customer?.externalReference}
              </i>
            </div>
          </div>
        </Link>
      ),
      sortable: false,
      right: false,
      id: 'customer',
    },
    {
      name: t('common.buildingSite'),
      selector: (row: GetQuotation) => row.buildingSite?.id,
      cell: (row: GetQuotation) => (
        <div className='column' data-tag='allowRowEvents'>
          <div data-tag='allowRowEvents'>
            <strong className='caption2medium' data-tag='allowRowEvents'>
              {row.buildingSite?.label}
            </strong>
          </div>
          <div data-tag='allowRowEvents'>
            <i className='caption2medium' data-tag='allowRowEvents'>
              {row.buildingSite?.externalReference}
            </i>
          </div>
        </div>
      ),
      sortable: false,
      right: false,
      id: 'buildingSite',
    },
    {
      name: t('common.creationDate'),
      selector: (row: GetQuotation) => row.createdAt,
      cell: (row: GetQuotation) => (
        <div className='column' data-tag='allowRowEvents'>
          <div data-tag='allowRowEvents'>
            <div className='caption2medium' data-tag='allowRowEvents'>
              {moment(row.createdAt).tz(siteTimeZone).format('DD/MM/YYYY, HH:mm')}
            </div>
          </div>
        </div>
      ),
      sortable: true,
      right: false,
      id: 'createdAt',
    },
    {
      name: t('common.status'),
      selector: (row: GetQuotation) => row.status,
      cell: (row: GetQuotation) => (
        <div className='column' data-tag='allowRowEvents'>
          <SelectStatus
            registerName={''}
            error={''}
            titleSize='none'
            forceSelectedOption={row.status}
            statusType={'quotation'}
            isSelectable={false}
            data-tag='allowRowEvents'
          />
        </div>
      ),

      sortable: true,
      right: false,
      id: 'status',
      grow: 1,
    },
    // {
    //   name: '',
    //   cell: (row: GetQuotation) => (
    //     <a href={seeDetails(row)} target="_blank" rel="noopener noreferrer" data-tag="allowRowEvents">
    //       <Icon icon="openLink" />
    //     </a>
    //   ),
    //   sortable: false,
    //   right: true,
    //   id: 'openLink',
    //   button: false,
    // },
  ];

  // #endregion specific

  // #endregion const init

  // #region event handling

  // useEffect(() => {
  //   fetchItems(1, perPage); // instead called by filtersHeader loading
  // }, []);

  const handlePageChange = (pageNumber: number) => {
    fetchItems(pageNumber, perPage);
  };

  const handlePerRowsChange = (newPerPage: any) => {
    setPerPage(newPerPage);
    fetchItems(1, newPerPage);
  };

  const onSort = (data: any, newSortOrder: any) => {
    if (data.id == sortColumn) {
      if (sortDirection == 'asc') {
        newSortOrder = 'desc';
      } else {
        newSortOrder = 'asc';
      }
    } else {
      newSortOrder = 'asc';
    }
    fetchItems(1, perPage, data.id, newSortOrder);
  };

  function handleSetFiltersAdvanced(newFilters: AdvancedFilter[]) {
    setFiltersAdvanced(newFilters);
    fetchItems(currentPage, perPage, sortColumn, sortDirection, filtersTab, newFilters);
  }

  function handleSetFiltersStatus(newFilters: string[]) {
    setFiltersTab(newFilters);
    fetchItems(currentPage, perPage, sortColumn, sortDirection, newFilters, filtersAdvanced);
  }

  // #endregion event handling

  return (
    <>
      <FilterHeader
        title={''}
        allowQuickSearch={true}
        allowAdvancedSearch={true}
        advancedSearchOptions={['search', 'number', 'externalReference', 'customer', 'buildingSite']}
        showAddNew={true}
        addNewText={t('common.new' + props.quotationType)}
        addNew={() => {
          history('./add');
        }}
        dropdown={true}
        listDropdown={[
          <Button
            key='customer'
            onClick={() => history('./add/customer')}
            label={t('common.customer')}
            style='white'
            size='thin'
          />,
          <Button
            key='jobSite'
            onClick={() => history('./add/buildingSite')}
            label={t('common.jobSite')}
            style='white'
            size='thin'
          />,
        ]}
        forcedFilters={props.forcedFilters}
        quickSearchFilterTypes={['search']}
        advancedSearchFilterChanged={handleSetFiltersAdvanced}
      />
      <TabFilter
        tabArray={tabFilterOptions}
        parent={'Quotation'}
        filterSet={handleSetFiltersStatus}
        defaultTab={props.defaultStatus ?? defaulTab}
      />
      {isLoading ?? false ? (
        <div>{t('common.loading')}</div>
      ) : (
        <DataTable
          className='table'
          noHeader={true}
          customStyles={TableStyles}
          columns={columns}
          onRowClicked={(row) => history(seeDetails(row))}
          highlightOnHover
          pointerOnHover
          data={filteredItems}
          noDataComponent={<div className='noResults'>{t('common.noResult')}</div>}
          defaultSortAsc={sortDirection == 'asc'}
          defaultSortFieldId={sortColumn}
          onSort={(data, newSortDirection) => onSort(data, newSortDirection)}
          sortServer
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          paginationDefaultPage={currentPage}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationPerPage={perPage}
          paginationRowsPerPageOptions={[5, 10, 20, 50, 100, 200]}
          paginationComponentOptions={{
            rowsPerPageText: t('common.perPage'),
            rangeSeparatorText: t('common.onPage'),
            noRowsPerPage: false,
            selectAllRowsItem: false,
            selectAllRowsItemText: 'All',
          }}
        />
      )}
    </>
  );
});

ListQuotations.displayName = 'ListQuotations';
export default ListQuotations;
