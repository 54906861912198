import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import TableStyles from '../styles/tableStyles';
import axios from 'axios';
import Config from '../Config';
import './Alertes.scss';
import '../styles/table.scss';
import { useNavigate } from 'react-router-dom';
import ToastComponent from './ToastComponent';
import Toast from '../models/Toast';
import { ToastTypes } from '../models/ToastTypes';
import Menu from './Menu';
import Header from './Header';
import { Alert } from '@dune-manager/backend-core/dist/models';
import moment from 'moment-timezone';
// import { Scopes } from '../models/Scopes';
// import Roles from '../utils/Roles';
import TabHeader from './TabHeader';
import { useTranslation } from 'react-i18next';
import { GlobalParameter } from '../models/GlobalParameter';

const Alertes = React.memo(() => {
  const { t } = useTranslation();
  const [totalRows, setTotalRows] = useState(0);
  const [totalResource, setTotalResource] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [perPage, setPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState('createdAt');
  const [sortDirection, setSortDirection] = useState('desc');
  // const [currentAlerte /*, setCurrentAlerte*/] = useState<Alert | undefined>();
  // const [isOpenUpdateAlerteWithdrawal, setIsOpenUpdateAlerteWithdrawal] = useState(false);
  const history = useNavigate();
  const [toasts, setToast] = useState<Toast[]>([]);
  const [isOpenEditAlertePopup, setIsOpenEditAlertePopup] = useState(false);
  const [optionsalertes, setOptionsAlertes] = useState<any[]>([]);
  const [filter, setFilter] = useState<any>();

  const [siteTimeZone, setSiteTimeZone] = useState<string>('Europe/Paris');
  useEffect(() => {
    const globalParametersSite: GlobalParameter[] = JSON.parse(localStorage.getItem('globalParameters') ?? '[]');
    setSiteTimeZone(globalParametersSite.find((x) => x.label === 'siteTimeZone')?.value ?? 'Europe/Paris');
  }, []);

  const setEditAcquitte = (row: any) => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');

    const url = orgid + '/alert' + `/${row.id}/acknowledge`;
    axios
      .get(Config.getApiExtranetUrl(url), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async () => {
        fetchAlertes(1, perPage);
      })
      .catch((error) => {
        // setIsLoading(false);
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  };

  const columns: any[] = [
    {
      name: t('common.severity'),
      selector: (row: any) => row.alertTypeLevel,
      cell: (row: any) => {
        if (row.alertTypeLevel === 'info') {
          return (
            <div className='alert-column-level'>
              <div className='severity-label low'>
                <strong className='caption2bold'>{t('common.low')}</strong>
              </div>
            </div>
          );
        }
        if (row.alertTypeLevel === 'warning') {
          return (
            <div className='alert-column-level'>
              <div className='severity-label medium'>
                <strong className='caption2bold'>{t('common.medium')}</strong>
              </div>
            </div>
          );
        }
        if (row.alertTypeLevel === 'error') {
          return (
            <div className='alert-column-level'>
              <div className='severity-label high'>
                <strong className='caption2bold'>{t('common.high')}</strong>
              </div>
            </div>
          );
        }
        if (row.alertTypeLevel === 'notification') {
          return (
            <div className='alert-column-level'>
              <div className='severity-label low'>
                <strong className='caption2bold'>{t('common.notification')}</strong>
              </div>
            </div>
          );
        }
      },
      sortable: false,
      right: false,
      grow: 1,
      id: 'alertLevel',
    },
    {
      name: t('common.type'),
      selector: (row: any) => row.alertTypeLabel,
      cell: (row: any) => (
        <div className='alert-column-label'>
          <div className='alert-column-label-cell'>
            <strong className='caption1'>{row.alertTypeDescription}</strong>
          </div>
          <div className='alert-column-label-origin'>
            <i className='caption1'>{row.origin}</i>
          </div>
        </div>
      ),
      sortable: false,
      right: false,
      id: 'type',
      grow: 4,
    },
    {
      name: t('common.description'),
      selector: (row: Alert) => row.description,
      cell: (row: Alert) => (
        <div className='alert-column-label description'>
          <strong className='caption1'>{row.description}</strong>
        </div>
      ),
      sortable: true,
      right: false,
      grow: 4,
      id: 'description',
    },
    {
      name: t('common.start'),
      selector: (row: Alert) =>
        moment(row.createdAt).isValid() ? moment(row.createdAt).tz(siteTimeZone).format('DD/MM/YY HH:mm') : '',
      cell: (row: Alert) => (
        <div className='alert-column-label datetime'>
          <div className='alert-column-label-date'>
            <strong className='caption1'>
              {moment(row.createdAt).isValid() ? moment(row.createdAt).tz(siteTimeZone).format('DD/MM/YY') : ''}
            </strong>
          </div>
          <div className='alert-column-label-time'>
            <i className='caption1'>
              {moment(row.createdAt).isValid() ? moment(row.createdAt).tz(siteTimeZone).format('HH:mm') : ''}
            </i>
          </div>
        </div>
      ),
      sortable: false,
      right: false,
      id: 'aparittion',
      grow: 3,
    },
    {
      name: t('common.end'),
      selector: (row: Alert) =>
        moment(row.disappearedAt).isValid() ? moment(row.disappearedAt).tz(siteTimeZone).format('DD/MM/YY HH:mm') : '',
      cell: (row: Alert) => (
        <div className='alert-column-label datetime'>
          <div className='alert-column-label-date'>
            <strong className='caption1'>
              {moment(row.disappearedAt).isValid() ? moment(row.disappearedAt).tz(siteTimeZone).format('DD/MM/YY') : ''}
            </strong>
          </div>
          <div className='alert-column-label-time'>
            <i className='caption1'>
              {moment(row.disappearedAt).isValid() ? moment(row.disappearedAt).tz(siteTimeZone).format('HH:mm') : ''}
            </i>
          </div>
        </div>
      ),
      sortable: false,
      right: false,
      id: 'disparition',
      grow: 3,
    },
    {
      name: '',
      selector: (row: Alert) =>
        moment(row.acknowledgedAt).isValid() ? (
          moment(row.acknowledgedAt).tz(siteTimeZone).format('DD/MM/YY HH:mm')
        ) : (
          <div className='validateBtn right-btn' onClick={() => setEditAcquitte(row)}>
            <h1>{t('common.validate')}</h1>
          </div>
        ),
      cell: (row: Alert) => (
        <div className='firstColumn'>
          {moment(row.acknowledgedAt).isValid() ? (
            <div className='acknowlegeBtn acknowledge'>
              <h1 className='base2'>{t('common.acknowledged')}</h1>
            </div>
          ) : (
            <div className='acknowlegeBtn' onClick={() => setEditAcquitte(row)}>
              <h1 className='base2'>{t('common.validate')}</h1>
            </div>
          )}
        </div>
      ),
      sortable: false,
      right: false,
      id: 'acquitte',
      grow: 2,
    },
  ];

  const onSuccessEditAlerte = () => {
    setIsOpenEditAlertePopup(false);
    addToast(t('common.alertUpdated'), ToastTypes.success);
    fetchAlertes(1, perPage);
  };

  const addToast = (message: string, type: ToastTypes) => {
    setToast([...toasts, new Toast(message, '', type)]);
  };

  const [filteredItems, setFilterereditems] = useState<Alert[]>([]);
  const [filterText, setFilterText] = useState('');

  useEffect(() => {
    AlertesInfoOptions();
    setFilterText('');
    fetchAlertes(1, perPage);
  }, []);

  // Acceder au detail des alertes
  const seeAlerteDns = (alert: Alert) => {
    // const token = localStorage.getItem('token');
    const url = 'alert';
    return `/${url}/${alert.id}/view`;
  };

  const fetchAlertes = (
    page: number,
    newPerPage: number,
    newFilterText: string = filterText,
    newSortColumn: string = sortColumn,
    newSortDirection: string = sortDirection,
    TabFilter: any = filter,
  ) => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');

    const url =
      orgid +
      '/alert/get?page=' +
      page +
      '&limit=' +
      newPerPage +
      '&search=' +
      newFilterText +
      '&sortBy=' +
      newSortColumn +
      '&orderBy=' +
      newSortDirection;

    setCurrentPage(page);
    setIsLoading(true);
    setFilterereditems([]);

    if (newFilterText != filterText) {
      setFilterText(newFilterText);
    }

    if (newSortColumn != sortColumn) {
      setSortColumn(newSortColumn);
    }

    if (newSortDirection != sortDirection) {
      setSortDirection(newSortDirection);
    }

    const dataToSend: SearchAlert = { acknowledged: TabFilter };

    axios
      .post(Config.getApiExtranetUrl(url), dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (res) => {
        setTotalRows(res.data.meta.total);
        setTotalResource(res.data.meta.total);
        setFilterereditems(res.data.content.items);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  };

  const handlePageChange = (pageNumber: number) => {
    fetchAlertes(pageNumber, perPage);
  };

  const handlePerRowsChange = (newPerPage: any) => {
    setPerPage(newPerPage);
    fetchAlertes(1, newPerPage);
  };

  const onSort = (data: any, newSortOrder: any) => {
    if (data.id == sortColumn) {
      if (sortDirection == 'asc') {
        newSortOrder = 'desc';
      } else {
        newSortOrder = 'asc';
      }
    } else {
      newSortOrder = 'asc';
    }
    fetchAlertes(1, perPage, filterText, data.id, newSortOrder);
  };

  const AlertesInfoOptions = () => {
    const optionsalertes: any[] = [];

    optionsalertes.push({ tabLabel: 'Tous', selected: true }, { tabLabel: 'Validé', selected: false });

    setOptionsAlertes(optionsalertes);
  };

  const ChangeFiltrer = (newfilter?: boolean) => {
    setFilter(newfilter ? newfilter : undefined);
    fetchAlertes(1, perPage, filterText, sortColumn, sortDirection, newfilter);
  };

  return (
    <div className='alertes'>
      <Menu selectedPage='alertes'></Menu>
      <ToastComponent toastList={toasts} position={'top-right'} autoDelete={true} dismissTime={4000} key={1} />

      <div className={'right table-container'}>
        <Header selectedPage={t('menu.alerts')}></Header>

        <TabHeader
          ClickAdd={setIsOpenEditAlertePopup}
          ClickExport={''}
          ClickImport={''}
          ClickFilter={''}
          label={t('common.alertsList')}
          labelButtonAdd={''}
          VisibleExport={false}
          VisibleImport={false}
          VisibleAdd={false}
          VisibleFilters={false}
        />

        {/* <TabFilter tabArray={optionsalertes} parent={'Alert'} Filtres={ChangeFiltrer} defaultTab={'Tous'} /> */}

        {isLoading ? (
          <div>{t('common.loading')}</div>
        ) : (
          <DataTable
            className='table'
            noHeader={true}
            customStyles={TableStyles}
            columns={columns}
            pointerOnHover={true}
            data={filteredItems}
            noDataComponent={<div className='noResults'>{'Aucun alerte'}</div>}
            defaultSortAsc={sortDirection == 'asc'}
            defaultSortFieldId={sortColumn}
            onSort={(data, newSortDirection) => onSort(data, newSortDirection)}
            sortServer
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationDefaultPage={currentPage}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationPerPage={perPage}
            paginationRowsPerPageOptions={[5, 10, 20, 50, 100, 200]}
            paginationComponentOptions={{
              rowsPerPageText: t('common.perPage'),
              rangeSeparatorText: t('common.onPage'),
              noRowsPerPage: false,
              selectAllRowsItem: false,
              selectAllRowsItemText: 'All',
            }}
          />
        )}
      </div>

      {/* <Popup
        open={isOpenEditAlertePopup}
        closeOnDocumentClick={true}
        closeOnEscape={true}
        onClose={() => setIsOpenEditAlertePopup(false)}
        className="popup"
        position={'top center'}
        contentStyle={popupContentStyle}
        overlayStyle={popupOverlayStyle}
      >
        <AddAlerte from="alert" onsuccess={onSuccessEditAlerte} closePopup={CallbackChildData} />
      </Popup> */}
    </div>
  );
});

class SearchAlert {
  acknowledged: boolean | undefined;
}

Alertes.displayName = 'Alertes';
export default Alertes;
