import React, { useEffect, useState } from 'react';
import './SelectWorkspace.scss';
// import { ReactComponent as User } from '../assets/svg/person.svg';
import { ReactComponent as Up } from '../assets/svg/arrow-ios-upward.svg';
import { ReactComponent as Down } from '../assets/svg/arrow-ios-downward.svg';
import Roles from '../utils/Roles';
import Workspace from '../models/Workspace';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Config from '../Config';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { loadStaticData } from '../store/staticData-slice';
import type { AppDispatch } from '../store';

function SelectWorkspace(props: any) {
  const [currentWorkspace, setcurrentWorkspace] = useState<Workspace | null>();
  const [isOpenSelectWorkspace, setIsOpenSelectWorkspace] = useState(false);
  const [workspaces, setWorkspaces] = useState<Workspace[]>([]);
  const [errorMessage, setErrorMessage] = useState('');

  const history = useNavigate();
  const dispatch = useDispatch<AppDispatch>(); // The `useDispatch` hook with AppDispatch type to call async thunk actions

  const onChangeWorkspace = (workspace: Workspace) => {
    setIsOpenSelectWorkspace(false);
    setcurrentWorkspace(workspace);
    Roles.setLocalStorage(workspace);
    dispatch(loadStaticData()); // Load static data once authenticated (called at refresh by APP as well)

    // Roles.setWorkspace(workspace);
    if (workspace.role === 'Mobile') {
      history('/account');
      location.reload();
    }
    if (props.parent == 'menu') {
      history('/trips');
    }
  };

  const openSelectWorkspace = () => {
    setIsOpenSelectWorkspace(!isOpenSelectWorkspace);
  };

  useEffect(() => {
    const workspaces = Roles.getWorkspaces();

    setWorkspaces(workspaces);

    if (props.parent == 'menu') {
      const curretntWp = Roles.getCurrentWorkspace();
      setcurrentWorkspace(curretntWp);
    } else {
      if (workspaces.length > 1) {
        const wpsOrdered = workspaces;

        const firstType = wpsOrdered[0];
        if (firstType != null) {
          setcurrentWorkspace(firstType);
          Roles.setLocalStorage(firstType);
          dispatch(loadStaticData()); // Load static data once authenticated (called at refresh by APP as well)
        }
      }
    }
  }, []);

  return (
    <div className="">
      <div className={'workspace selected'} onClick={() => openSelectWorkspace()}>
        <div className="selectContent">
          <div className="selectOrganizationName">{currentWorkspace?.organization?.name}</div>
          <div className="selectorganizationRole">{currentWorkspace?.role}</div>
        </div>
        <div className="buttonsSelect">
          <div>
            <Up className="" width="25" height="20" fill={'#90a0b7'} />
          </div>
          <div>
            <Down className="" width="25" height="20" fill={'#90a0b7'} />
          </div>
        </div>
      </div>
      {isOpenSelectWorkspace ? (
        <div className={'selectWorkspace oppenned'}>
          <div className="user">
            {/* <User className="icon" width="25" height="20" fill={'#90a0b7'} /> */}
            <div>{Roles.getEmail()}</div>
          </div>
          <div key="site">
            <div className="title"></div>
            {workspaces
              .sort((a, b) => (a.organization?.name ?? '').localeCompare(b.organization?.name ?? ''))
              .map((workspace, index) => {
                return (
                  <div
                    key={index + (workspace.organization?.uuid ?? '-')}
                    className={
                      currentWorkspace?.organization?.uuid == workspace?.organization?.uuid
                        ? 'workspace selected '
                        : 'workspace'
                    }
                    onClick={() => onChangeWorkspace(workspace)}
                  >
                    <div className="selectContent">
                      <div className="selectOrganizationName">{workspace?.organization?.name}</div>
                      <div className="selectorganizationRole">{workspace?.role}</div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default SelectWorkspace;
