import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Config from '../../../Config';
import axios from 'axios';
import useToast from '../../../hooks/use-toast';
import { ToastTypes } from '../../../models/ToastTypes';
import { Head } from '../../../stories/dune/molecules/Head';
import Menu from '../../Menu';
import GeneralInvoice from './GeneralInvoice';
import SubMenuInvoice from './SubMenuInvoice';
import { GetInvoice, InvoiceStatus, InvoiceType } from '../../../models/Invoice';
import PopupContainer from '../../../stories/dune/organisms/PopupContainer';
import ListTrips from '../../lists/Trips/ListTrips';
import ListAdditionalDocs from '../../lists/AdditionalDocs/ListAdditionalDocs';
import moment from 'moment';

interface DetailInvoiceProps {
  id?: string;

  isPopup?: boolean;
  onClosePopup?: (createdId: string | undefined) => void;
  mode?: string;
}

const DetailInvoice = React.memo((props: DetailInvoiceProps) => {
  // #region generic

  const { t } = useTranslation();
  const { addToast, addFixedToast } = useToast();

  const [isLoading, setIsLoading] = useState(true);
  const [isAddingLoading, setIsAddingLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState('general');

  const history = useNavigate();

  type Params = {
    id: string;
    mode: string;
    autosign?: string;
  };

  const { id, mode, autosign } = useParams<Params>();

  function updateCurrentTab(mode: string) {
    setCurrentTab(mode);
  }

  function getMode() {
    return props.mode ?? mode ?? '';
  }

  // #endregion generic

  // #region specific
  const [getInvoice, setGetInvoice] = useState<GetInvoice>();

  function getCurrentInvoice(id: string | undefined) {
    const shouldUpdate = id && getMode() === 'update';

    if (!shouldUpdate) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');
    const baseUrl = orgid + '/invoice/detail/';
    const url = Config.getApiExtranetUrl(baseUrl + id);

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if ((res.data.content ?? null) !== null) {
          // on enregistre GetInvoice
          setGetInvoice(res.data.content);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        if ((id ?? null) !== null) {
          addToast(
            (error?.response?.data?.code
              ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
              : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
            ToastTypes.error,
          );
        }
      });
  }

  useEffect(() => {
    getCurrentInvoice(id);
  }, [id, getMode()]);

  // #endregion specific

  const invoiceStatus = getInvoice
    ? getInvoice.status === InvoiceStatus.UPCOMING && getInvoice.type === InvoiceType.PROFORMA
      ? InvoiceType.PROFORMA
      : getInvoice.status === InvoiceStatus.UPCOMING &&
        getInvoice.invoiceDueDate !== undefined &&
        moment(getInvoice.invoiceDueDate) < moment(new Date())
      ? 'overdue'
      : getInvoice.status
    : '';

  const content = (
    <>
      {!isLoading && (
        <>
          {getMode() === 'add' && (
            <GeneralInvoice
              mode={getMode()}
              invoiceData={getInvoice}
              onCancel={onCanceled}
              onConfirm={onConfirmed}
              onReload={() => getCurrentInvoice(id)}
            />
          )}

          {getMode() !== 'add' && (
            <>
              {/* <SubMenuInvoice tab={currentTab} updateTab={updateCurrentTab} /> // TODO : a remettre quand filtre trip et docs fonctionnels */}
              {currentTab === 'general' && (
                <GeneralInvoice
                  id={id}
                  mode={getMode()}
                  invoiceData={getInvoice}
                  onCancel={onCanceled}
                  onConfirm={onConfirmed}
                  onReload={() => getCurrentInvoice(id)}
                />
              )}
              {currentTab === 'trip' && id && (
                <ListTrips
                  canAddNew={false}
                  forcedFilters={[
                    {
                      filterType: 'invoice',
                      filterData: {
                        value: getInvoice?.id,
                        label: getInvoice?.invoiceNumber + ' - ' + getInvoice?.externalReference,
                        data: getInvoice,
                      },
                    },
                  ]}
                />
              )}
              {currentTab === 'documents' && getInvoice && (
                <ListAdditionalDocs entityType='invoice' entityId={getInvoice.id ?? ''} canAddNew={true} />
              )}
            </>
          )}
        </>
      )}
    </>
  );

  function onConfirmed(data: any) {
    if (props.isPopup) {
      if (props.onClosePopup === undefined) throw new Error('Function not implemented.');
      else props.onClosePopup(data);
    } else {
      history('/invoicing/invoices/' + invoiceStatus);
    }
  }

  function onCanceled() {
    if (props.isPopup) {
      if (props.onClosePopup === undefined) throw new Error('Function not implemented.');
      else props.onClosePopup(undefined);
    } else {
      history('/invoicing/invoices/' + invoiceStatus);
    }
  }

  return (
    <>
      {props.isPopup ? (
        <PopupContainer
          hideBottomBar={true}
          isLarge={true}
          title={
            getMode() === 'add'
              ? t('common.newInvoice')
              : !getInvoice
              ? ''
              : t('invoiceType.' + getInvoice?.type) +
                ' ' +
                getInvoice.invoiceNumber +
                (getInvoice.externalReference ? '-' + getInvoice.externalReference : '')
          }
        >
          {content}
        </PopupContainer>
      ) : (
        <div className='main-container'>
          <Menu selectedPage='invoices'></Menu> {/* TODO : sortir et mettre dans classe 'parent'*/}
          <div className='right detail-container'>
            <Head
              title={
                getMode() === 'add'
                  ? t('common.newInvoice')
                  : !getInvoice
                  ? ''
                  : getInvoice.customer?.label +
                    ' / ' +
                    t('invoiceType.' + getInvoice?.type) +
                    ' ' +
                    getInvoice.invoiceNumber +
                    (getInvoice.externalReference ? '-' + getInvoice.externalReference : '') +
                    (invoiceStatus === InvoiceType.PROFORMA ? '' : ' - ' + t('status.' + invoiceStatus))
              }
              titleType='h2'
              back={true}
              OnNotificationClick={function (): void {
                throw new Error('Function not implemented.');
              }}
              OnAutorisationClick={function (): void {
                throw new Error('Function not implemented.');
              }}
              Back={function (): void {
                history('/invoicing/invoices/' + invoiceStatus);
              }}
            />
            {content}
          </div>
        </div>
      )}
    </>
  );
});

DetailInvoice.displayName = 'DetailInvoice';
export default DetailInvoice;
