import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './ZoneProductList.scss';
import { Button } from '../../../stories/dune/atoms/Button';
import { GetZone } from '../../../models/Zone';
import { Icon } from '../../../stories/dune/atoms/Icon';
import { useNavigate } from 'react-router';
import useToast from '../../../hooks/use-toast';
import Product from '../../../models/Product';
import { SelectProduct, SelectProductOption } from '../../forms/SelectProduct';
import PopupContainer from '../../../stories/dune/organisms/PopupContainer';

interface ZoneProductListProps {
  id?: string;
  zoneData: GetZone | undefined;

  onRemove: (data: any) => void;
  onAdd: (data: any) => void;
}

const ZoneProductList = React.memo((props: ZoneProductListProps) => {
  const { t } = useTranslation();
  const history = useNavigate();
  const { addToast, addFixedToast } = useToast();

  const [isAddingLoading, setIsAddingLoading] = useState(false);

  const [isOpenAddProduitPopup, setIsOpenAddProduitPopup] = useState(false);
  const [isOpenConfirmDeleteProduct, setIsOpenConfirmDeleteProduct] = useState(false);
  const [currentProductToDelete, setCurrentProductToDelete] = useState<Product>();

  const onClickDeleteProduct = (e: any) => {
    setIsOpenConfirmDeleteProduct(true);
    setCurrentProductToDelete(e);
  };

  const onDeleteProductOk = (e: Product | undefined) => {
    if (props.onRemove && e) props.onRemove(e);
  };
  const onSuccessAddProduit = (e: SelectProductOption) => {
    if (props.onAdd && e) props.onAdd(e.data);
  };

  return (
    <div className='detailZone detail-content'>
      <div className='detail-form'>
        <div id='products-tab' className='detail-form detail-products'>
          {/* todo : add filter & voir avec max si y'a une route pour filtrer les produits */}
          <div className='section-title'>
            <div>
              <h3 className='title1SemiBold'>{t('menu.products')}</h3>
            </div>
            <Button
              iconLeft='add'
              label={t('common.add')}
              style='primary'
              onClick={() => setIsOpenAddProduitPopup(true)}
            />
          </div>
          <div className='produitList'>
            <div className='productlist-Header'>
              <div className='productlist-HeaderItem extRef'>
                <h1 className='caption2medium'>{t('common.yourReference')}</h1>
              </div>
              <div className='productlist-HeaderItem label'>
                <h1 className='caption2medium'>{t('common.label')}</h1>
              </div>
              <div className='productlist-HeaderItem priority'>
                <h1 className='caption2medium'>{t('common.priority')}</h1>
              </div>
              <div className='productlist-HeaderItem delete'></div>
            </div>
            {props.zoneData?.products != undefined && props.zoneData?.products.length > 0 ? (
              <>
                {props.zoneData?.products?.map((product: any) => {
                  return (
                    <div className='productlist-Row' key={product.id}>
                      <div className='productlist-Item extRef'>
                        <h1 className='caption1'>{product.externalReference}</h1>
                      </div>
                      <div className='productlist-Item label'>
                        <h1 className='caption1'>{product.label}</h1>
                      </div>
                      <div className='productlist-Item priority'>
                        <div className='labelPriority' id={product.id}>
                          <div className='caption2bold'>
                            {t('common.priority')} {product.ProductZone?.priority}
                          </div>
                          {/* <SvgEye className="svg" /> */}
                        </div>
                      </div>
                      <div
                        className='productlist-Item delete'
                        onClick={() => onClickDeleteProduct(product)}
                        id={product.id}
                      >
                        <Icon icon='close' />
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <div className='productlist-Row base2'>
                <strong>{t('common.noProductOnZone')}</strong>
              </div>
            )}
          </div>
        </div>
      </div>

      <div>
        {isOpenAddProduitPopup && (
          <SelectProduct
            registerName='products'
            error={''}
            isSelectable={true}
            selectedOptionChanged={undefined}
            isPopup={true}
            onClosePopup={(selectedOption: SelectProductOption) => {
              if (selectedOption) {
                onSuccessAddProduit(selectedOption);
              }
              setIsOpenAddProduitPopup(false);
            }}
          />
        )}

        {isOpenConfirmDeleteProduct && (
          <PopupContainer
            message={t('common.confirmDelete') + ' ' + currentProductToDelete?.label + ' ?'}
            onCancel={function (): void {
              setIsOpenConfirmDeleteProduct(false);
            }}
            onConfirm={function (): void {
              setIsOpenConfirmDeleteProduct(false);
              onDeleteProductOk(currentProductToDelete);
            }}
            confirmLabel={t('common.yes')}
            confirmIcon='delete'
            cancelLabel={t('common.no')}
          />
        )}
      </div>
    </div>
  );
});

ZoneProductList.displayName = 'ZoneProductList';
export default ZoneProductList;
