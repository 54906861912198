import React, { useEffect, useState } from 'react';
import { Language } from '../../../i18n';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastTypes } from '../../../models/ToastTypes';
import useToast from '../../../hooks/use-toast';
import Config from '../../../Config';
import FilterHeader, { AdvancedFilter } from '../FilterHeader';
import { GetContact, GetContactParameters } from '../../../models/Contact';
import { ContactEntity } from '@dune-manager/backend-core/dist/models/contact';
import DataTable from 'react-data-table-component';
import TableStyles from '../../../styles/tableStyles';
import { Icon } from '../../../stories/dune/atoms/Icon';
import FormContact from '../../forms/FormContact';
import Switch from 'react-switch';
import stringUtils from '../../../utils/stringUtils';
import PopupContainer from '../../../stories/dune/organisms/PopupContainer';
interface ListContactsProps {
  contactEntity: ContactEntity;
  entityId: string;
  canAdd: boolean;
  canEdit: boolean;
  canRemove: boolean;
  canSelect: boolean;
  selectedData?: GetContact[];
  addSelectedData?: (data: GetContact) => void;
  removeSelectedData?: (id: string) => void;
}

const ListContacts = React.memo((props: ListContactsProps) => {
  // #region const init

  // #region generic
  const { t } = useTranslation();
  const currentLanguage = localStorage.getItem('language') ?? Language.FR;
  const { addToast, addFixedToast } = useToast();
  const [isAddingLoading, setIsAddingLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const history = useNavigate();

  const [filtersAdvanced, setFiltersAdvanced] = useState<AdvancedFilter[]>([]);
  const [filtersTab, setFiltersTab] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState(20);
  const [sortColumn, setSortColumn] = useState('firstname');
  const [sortDirection, setSortDirection] = useState('asc');
  const [totalRows, setTotalRows] = useState(0);
  const [totalResource, setTotalResource] = useState(0);

  // #endregion generic

  // #region specific

  const [isOpenConfirmDeleteContact, setIsOpenConfirmDeleteContact] = useState(false);
  const [currentContactToDelete, setCurrentContactToDelete] = useState<GetContact | undefined>();

  // #region add contact
  const [showAddContact, setShowAddContact] = useState(false);

  const [id, setId] = useState<string>();
  const [firstname, setFirstname] = useState<string>();
  const [lastname, setLastname] = useState<string>();
  const [telephone, setTelephone] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [companyRole, setCompanyRole] = useState<string>();
  // #endregion add contact

  const [filteredItems, setFilteredItems] = useState<GetContact[]>([]);

  const fetchItems = (
    page: number,
    newPerPage: number,
    newSortColumn: string = sortColumn,
    newSortDirection: string = sortDirection,
    TabFilter: string[] = filtersTab,
    AdvancedFilters: AdvancedFilter[] = filtersAdvanced,
  ) => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');

    const url =
      orgid +
      '/contact/get?page=' +
      page +
      '&limit=' +
      newPerPage +
      '&sortBy=' +
      newSortColumn +
      '&orderBy=' +
      newSortDirection;

    setCurrentPage(page);
    setIsLoading(true);
    setFilteredItems([]);

    if (newSortColumn != sortColumn) {
      setSortColumn(newSortColumn);
    }

    if (newSortDirection != sortDirection) {
      setSortDirection(newSortDirection);
    }

    axios
      .post(
        Config.getApiExtranetUrl(url),
        { ...getFiltersToSend(TabFilter, AdvancedFilters) },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(async (res) => {
        setFilteredItems(res.data.content.items);

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  };

  function getFiltersToSend(tabFilters: string[], advancedFilters: AdvancedFilter[]) {
    const toSend: GetContactParameters = { contactEntity: props.contactEntity, entityId: props.entityId };

    // if (tabFilters?.length > 0) toSend.status = tabFilters;

    if (advancedFilters.length > 0) {
      advancedFilters.map((x) => {
        if (x.filterType === 'firstname') toSend.firstname = x.filterData;
        if (x.filterType === 'lastname') toSend.lastname = x.filterData;
        if (x.filterType === 'email') toSend.email = x.filterData;
        if (x.filterType === 'telephone') toSend.telephone = x.filterData;
      });
    }

    return toSend;
  }

  // const tabFilterOptions: any = [];

  // const seeDetails = (row: GetContact) => {
  //   // TODO INTEGRATION : gestion popup si dans sous-fenetre ?
  //   history('./' + row.id + '/update');
  // };

  const columns: any[] = [
    {
      name: t('common.lastname'),
      selector: (row: GetContact) => row.lastname,
      cell: (row: GetContact) => (
        <div className='column'>
          <div>
            <strong className='caption2medium'>{row.lastname}</strong>
          </div>
        </div>
      ),
      sortable: true,
      right: false,
      id: 'lastname',
    },
    {
      name: t('common.firstname'),
      selector: (row: GetContact) => row.firstname,
      cell: (row: GetContact) => (
        <div className='column'>
          <div>
            <strong className='caption2medium'>{row.firstname}</strong>
          </div>
        </div>
      ),
      sortable: true,
      right: false,
      id: 'firstname',
    },
    {
      name: t('common.telephone'),
      selector: (row: GetContact) => row.telephone,
      cell: (row: GetContact) => (
        <div className='column'>
          <div>
            <strong className='caption2medium'>{row.telephone}</strong>
          </div>
        </div>
      ),
      sortable: true,
      right: false,
      id: 'telephone',
    },
    {
      name: t('common.email'),
      selector: (row: GetContact) => row.email,
      cell: (row: GetContact) => (
        <div className='column'>
          <div>
            <strong className='caption2medium'>{row.email}</strong>
          </div>
        </div>
      ),
      sortable: true,
      right: false,
      id: 'email',
    },
    {
      name: t('common.companyRole'),
      selector: (row: GetContact) => row.companyRole,
      cell: (row: GetContact) => (
        <div className='column'>
          <div>
            <strong className='caption2medium'>{row.companyRole}</strong>
          </div>
        </div>
      ),
      sortable: true,
      right: false,
      id: 'companyRole',
    },
    {
      name: '',
      sortable: false,
      cell: (row: any) => (
        <div
          className='column-last'
          onClick={() => {
            onClickEditContact(row);
          }}
        >
          <Icon icon={'edit'} />
        </div>
      ),
      button: true,
      right: true,
      id: 'edit',
      omit: !(props.canEdit ?? false),
      minWidth: '3%',
      grow: 1,
    },
    {
      name: '',
      sortable: false,
      cell: (row: any) => (
        <div
          className='column-last'
          onClick={() => {
            onClickRemoveContact(row);
          }}
        >
          <Icon icon={'close'} />
        </div>
      ),
      button: true,
      right: true,
      id: 'remove',
      omit: !(props.canRemove ?? false),
      minWidth: '3%',
      grow: 1,
    },
    {
      selector: (row: GetContact) => row.id,
      cell: (row: GetContact) => (
        // TODO DESIGN : mettre une jolie coche à la place ?
        <Switch
          className='base2'
          type='text'
          checked={(props.selectedData && props.selectedData?.findIndex((x) => x.id === row.id) !== -1) ?? false}
          onChange={(checked: boolean, event: any, id: string) => {
            if (checked && props.addSelectedData) {
              props.addSelectedData(row);
            } else if (props.removeSelectedData) {
              props.removeSelectedData(row.id);
            }
          }}
          onColor={'#2a85ff'}
        />
      ),
      sortable: true,
      right: true,
      id: 'select',
      omit: !props.canSelect,
    },
  ];

  const onClickRemoveContact = (e: GetContact) => {
    setIsOpenConfirmDeleteContact(true);
    setCurrentContactToDelete(e);
  };

  const onRemoveContactOk = (e: GetContact) => {
    if (props.canRemove) removeContact(e);
    setIsOpenConfirmDeleteContact(false);
  };

  function removeContact(e: GetContact) {
    setIsAddingLoading(true);

    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');
    const baseUrl = orgid + '/contact/' + e.id + '/remove';

    const dataToSend = {
      entityId: props.entityId,
      contactEntity: props.contactEntity,
    };

    axios
      .post(Config.getApiExtranetUrl(baseUrl), stringUtils.formatFieldsForPost(dataToSend), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        addToast(t('common.contactRemoved'), ToastTypes.success);
        setIsAddingLoading(false);
        fetchItems(1, perPage);
      })
      .catch((error) => {
        setIsAddingLoading(false);
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  }

  function onClickAddContact() {
    if (showAddContact) {
      setShowAddContact(false);
    } else {
      setId(undefined);
      setFirstname(undefined);
      setLastname(undefined);
      setTelephone(undefined);
      setEmail(undefined);
      setCompanyRole(undefined);
      setShowAddContact(true);
    }
  }

  function handleCancelAddContact() {
    setShowAddContact(false);
  }

  function handleSuccessAddContact() {
    setIsAddingLoading(false);
    setShowAddContact(false);
    fetchItems(1, perPage);
  }

  function onClickEditContact(row: GetContact) {
    if (showAddContact) {
      setShowAddContact(false);
    } else {
      setId(row.id);
      setFirstname(row.firstname);
      setLastname(row.lastname);
      setTelephone(row.telephone);
      setEmail(row.email);
      setCompanyRole(row.companyRole);
      setShowAddContact(true);
    }
  }

  // #endregion specific

  // #endregion const init

  // #region event handling

  // useEffect(() => {
  //   fetchItems(1, perPage); // instead called by filtersHeader loading
  // }, []);

  const handlePageChange = (pageNumber: number) => {
    fetchItems(pageNumber, perPage);
  };

  const handlePerRowsChange = (newPerPage: any) => {
    setPerPage(newPerPage);
    fetchItems(1, newPerPage);
  };

  const onSort = (data: any, newSortOrder: any) => {
    if (data.id == sortColumn) {
      if (sortDirection == 'asc') {
        newSortOrder = 'desc';
      } else {
        newSortOrder = 'asc';
      }
    } else {
      newSortOrder = 'asc';
    }
    fetchItems(1, perPage, data.id, newSortOrder);
  };

  function handleSetFiltersAdvanced(newFilters: AdvancedFilter[]) {
    setFiltersAdvanced(newFilters);
    fetchItems(currentPage, perPage, sortColumn, sortDirection, filtersTab, newFilters);
  }

  // function handleSetFiltersStatus(newFilters: string[]) {
  //   setFiltersTab(newFilters);
  //   fetchItems(currentPage, perPage, sortColumn, sortDirection, newFilters, filtersAdvanced);
  // }

  // #endregion event handling

  return (
    <>
      <FilterHeader
        title={''}
        allowQuickSearch={true}
        allowAdvancedSearch={false}
        advancedSearchOptions={['firstname', 'lastname', 'email', 'telephone']}
        showAddNew={props.canAdd}
        addNewText={t('common.add')}
        addNew={onClickAddContact}
        quickSearchFilterTypes={['firstname']}
        advancedSearchFilterChanged={handleSetFiltersAdvanced}
      />
      {showAddContact && (
        <FormContact
          contactEntity={props.contactEntity}
          entityId={props.entityId}
          id={id ?? undefined}
          lastname={lastname ?? ''}
          firstname={firstname ?? ''}
          telephone={telephone ?? ''}
          email={email ?? ''}
          companyRole={companyRole ?? ''}
          isPopup={false}
          onClosePopup={(validate: boolean) => {
            if (validate) {
              handleSuccessAddContact();
            } else {
              handleCancelAddContact();
            }
          }}
        />
      )}
      {/* <TabFilter tabArray={[]} parent={'Contact'} filterSet={() => console.log('tt')} defaultTab={'Tous'} /> */}
      {isLoading ?? false ? (
        <div>{t('common.loading')}</div>
      ) : (
        <>
          <DataTable
            className='table'
            noHeader={true}
            customStyles={TableStyles}
            columns={columns}
            data={filteredItems}
            noDataComponent={<div className='noResults'>{t('common.noResult')}</div>}
            defaultSortAsc={sortDirection == 'asc'}
            defaultSortFieldId={sortColumn}
            onSort={(data, newSortDirection) => onSort(data, newSortDirection)}
            sortServer
          />
          {isOpenConfirmDeleteContact && (
            <PopupContainer
              message={`${t('common.confirmDelete')} '${currentContactToDelete?.firstname}' ?`}
              onCancel={function (): void {
                setIsOpenConfirmDeleteContact(false);
              }}
              onConfirm={function (): void {
                setIsOpenConfirmDeleteContact(false);
                if (currentContactToDelete) onRemoveContactOk(currentContactToDelete);
              }}
              confirmLabel={t('common.yes')}
              confirmIcon='delete'
              cancelLabel={t('common.no')}
            />
          )}
        </>
      )}
    </>
  );
});

ListContacts.displayName = 'ListContacts';
export default ListContacts;
