import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';

import styles from './popupContainer.module.scss';
import { useTranslation } from 'react-i18next';
import { Title } from '../atoms/Title';
import { Button } from '../atoms/Button';
import { IconType } from '../atoms/Icon';

// to use, add :
// <div id="backdrop-root"></div>
// <div id="overlay-root"></div>
// above :
// <div id="root"></div>
// in public/index.html

interface PopupContainerProps {
  title?: string;
  message?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmLabel?: string;
  confirmIcon?: IconType;
  cancelLabel?: string;
  children?: ReactNode | undefined;
  overlayContentIsChildren?: boolean;
  hideBottomBar?: boolean;
  isLarge?: boolean;
}

function Backdrop(props: PopupContainerProps) {
  return <div className={styles.backdrop} onClick={props.onCancel} />;
}

function ModalOverlay(props: PopupContainerProps) {
  const { t } = useTranslation();

  const addLineBreak = (str: string) =>
    str.split('\n').map((subStr) => {
      return (
        <>
          {subStr}
          <br />
        </>
      );
    });

  return (
    <div className={`${styles.popup} ${props.isLarge ? styles.large : ''}`}>
      <div className={styles.body}>
        {props.title && <Title label={props.title} />}
        {props.message && (
          <div className={styles.content}>
            <p>{addLineBreak(props.message)}</p>
          </div>
        )}
        {props.children}
      </div>
      {!props.hideBottomBar && (
        <div className={styles.bottomBar}>
          <Button
            label={props.cancelLabel ?? t('common.cancel')}
            size="medium"
            style={'quaternary'}
            onClick={props.onCancel}
          />
          <Button
            label={props.confirmLabel ?? t('common.add')}
            size="medium"
            style={'primary'}
            iconLeft={props.confirmIcon ?? 'add'}
            onClick={props.onConfirm}
          />
        </div>
      )}
    </div>
  );
}

const PopupContainer = (props: PopupContainerProps) => {
  const overlay = document.getElementById('overlay-root');
  const backdrop = document.getElementById('backdrop-root');

  if (!overlay) {
    console.error('Element with id "overlay-root" not found');
    return null;
  }
  if (!backdrop) {
    console.error('Element with id "backdrop-root" not found');
    return null;
  }

  return (
    <React.Fragment>
      {ReactDOM.createPortal(<Backdrop {...props}></Backdrop>, backdrop)}
      {ReactDOM.createPortal(
        props.overlayContentIsChildren ? (
          <>{props.children}</>
        ) : (
          <ModalOverlay {...props}>{props.children}</ModalOverlay>
        ),
        overlay,
      )}
    </React.Fragment>
  );
};

export default PopupContainer;
