import { SelectData, SelectDataOption, SelectProps } from '../../stories/dune/molecules/SelectData';
import axios from 'axios';
import { useState } from 'react';
import Config from '../../Config';
import { useTranslation } from 'react-i18next';
import { GetCarrier } from '../../models/Carrier';
import DetailCarrier from '../pages/Carriers/DetailCarrier';

// import DetailCarrier from '../pages/Carriers/DetailCarrier';

export interface SelectCarrierOption extends SelectDataOption {
  data: GetCarrier;
}

export const SelectCarrier = ({
  setValue,
  register,
  registerName = 'client',
  error,
  selectedOptionChanged,
  searchParent,
  dependsOnSearchParent = false,
  initialOption = undefined,
  forceSelectedOption = undefined,
  ...props
}: SelectProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const [options, setOptions] = useState<SelectCarrierOption[]>([]);
  const [createdOption, setCreatedOption] = useState<SelectCarrierOption | undefined>();
  const [filterText, setFilterText] = useState('');

  const { t } = useTranslation();

  const fetchData = (filterText: string) => {
    setFilterText(filterText);
    if (filterText.length > 0) {
      setIsLoading(true);
      const token = localStorage.getItem('token');
      const orgid = localStorage.getItem('orgid');

      const baseUrl = '/carrier/get';
      const url = orgid + `${baseUrl}?onlyActive=true&page=${1}&limit=${200}&search=${filterText}`;

      axios
        .get(Config.getApiExtranetUrl(url), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setIsLoading(false);
          setFetchedOptions(res.data.content.items);
        })
        .catch(() => {
          setIsLoading(false);
          setFetchedOptions([]);
        });
    } else {
      setFetchedOptions([]);
    }
  };

  const setFetchedOptions = (fetchedOptions: GetCarrier[]) => {
    const options: any[] = [];

    if (fetchedOptions != null) {
      fetchedOptions.forEach((s) => {
        const option = {
          value: s.id,
          label: s.label + (s.externalReference ? ' - ' + s.externalReference : ''),
          data: s,
        };

        options.push(option);
      });

      setOptions(options);
    }
  };

  const [showAddPopup, setShowAddPopup] = useState(false);

  function addPopupClosedHandler(createdId: string | undefined) {
    setShowAddPopup(false);

    if (createdId) {
      const token = localStorage.getItem('token');
      const orgid = localStorage.getItem('orgid');

      const baseUrl = '/carrier/detail/';
      const url = orgid + baseUrl + createdId;

      axios
        .get(Config.getApiExtranetUrl(url), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setIsLoading(false);
          setCreatedOption({
            value: res.data.content.id,
            label:
              res.data.content.label +
              (res.data.content.externalReference ? ' - ' + res.data.content.externalReference : ''),
            data: res.data.content,
          });
        })
        .catch(() => {
          setIsLoading(false);
          setFetchedOptions([]);
        });
    }
  }

  return (
    <>
      {showAddPopup && <DetailCarrier mode='add' isPopup={true} onClosePopup={addPopupClosedHandler} />}
      <SelectData
        title={props.titleOverride ?? t('common.carrier')}
        placeholder={t('common.carrierChoose')}
        titleSize={props.titleSize ?? 'large'}
        setValue={setValue}
        register={register}
        registerName={registerName}
        error={error}
        isSelectable={props.isSelectable}
        selectedOptionChanged={selectedOptionChanged}
        clearOnSelect={props.clearOnSelect}
        isLoading={isLoading}
        options={options}
        searchParent={searchParent}
        dependsOnSearchParent={dependsOnSearchParent}
        initialOption={initialOption}
        forceSelectedOption={createdOption ?? forceSelectedOption}
        filterText={filterText}
        fetch={fetchData}
        onClickNoResult={() => {
          setShowAddPopup(true);
        }}
        isPopup={props.isPopup}
        onClosePopup={props.onClosePopup}
        noResultText={props.noResultTextOverride}
      ></SelectData>
    </>
  );
};
