import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import TableStyles from '../../../styles/tableStyles';
import axios from 'axios';
import Config from '../../../Config';
import { Link, useNavigate } from 'react-router-dom';
import { Product } from '@dune-manager/backend-core/dist/models';
import { useTranslation } from 'react-i18next';
import TabFilter from '../TabFilter';
import FilterHeader, { AdvancedFilter } from '../FilterHeader';
import { GetProduct, GetProductParameters } from '../../../models/Product';
import Switch from 'react-switch';
import { Icon } from '../../../stories/dune/atoms/Icon';
import useToast from '../../../hooks/use-toast';
import { ToastTypes } from '../../../models/ToastTypes';
import { isActiveList } from '../../../models';

interface ListProductsProps {
  canAddNew: boolean;
  defaultStatus?: string;
  forcedFilters?: AdvancedFilter[];
}

const ListProducts = React.memo((props: ListProductsProps) => {
  const { t } = useTranslation();
  const { addToast, addFixedToast } = useToast();

  const [totalRows, setTotalRows] = useState(0);
  const [totalResource, setTotalResource] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [perPage, setPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState('label');
  const [sortDirection, setSortDirection] = useState('asc');
  // const [currentVehicle /*, setCurrentVehicle*/] = useState<Vehicle | undefined>();
  // const [isOpenUpdateVehicleWithdrawal, setIsOpenUpdateVehicleWithdrawal] = useState(false);
  const history = useNavigate();
  const [filtersAdvanced, setFiltersAdvanced] = useState<AdvancedFilter[]>(props.forcedFilters ?? []);
  const [filtersTab, setFiltersTab] = useState<string[]>([]);

  const [filteredItems, setFilteredItems] = useState<Product[]>([]);
  const [isActive, setIsActive] = useState<isActiveList>({});
  const [multiProductPriority, setMultiProductPriority] = useState<isActiveList>({});

  const fetchItems = (
    page: number,
    newPerPage: number,
    newSortColumn: string = sortColumn,
    newSortDirection: string = sortDirection,
    TabFilter: string[] = filtersTab,
    AdvancedFilters: AdvancedFilter[] = filtersAdvanced,
  ) => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');

    const url =
      orgid +
      '/product/get?page=' +
      page +
      '&limit=' +
      newPerPage +
      '&sortBy=' +
      newSortColumn +
      '&orderBy=' +
      newSortDirection +
      getSearchQuery(AdvancedFilters);

    setCurrentPage(page);
    setIsLoading(true);
    setFilteredItems([]);

    if (newSortColumn != sortColumn) {
      setSortColumn(newSortColumn);
    }

    if (newSortDirection != sortDirection) {
      setSortDirection(newSortDirection);
    }

    axios
      .post(
        Config.getApiExtranetUrl(url),
        { ...getFiltersToSend(TabFilter, AdvancedFilters) },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(async (res) => {
        setTotalRows(res.data.meta.total);
        setTotalResource(res.data.meta.total);
        setFilteredItems(res.data.content.items);
        setIsActive(
          res.data.content.items.reduce((acc: isActiveList, product: Product) => {
            acc[product.id] = product.isActive;
            return acc;
          }, {}),
        );

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
      });
  };

  function getSearchQuery(advancedFilters: AdvancedFilter[]) {
    let toSend = '';

    // if (tabFilters?.length > 0) toSend.status = tabFilters;
    if (advancedFilters.length > 0) {
      advancedFilters.map((x) => {
        if (x.filterType === 'labelAndReference') toSend = '&search=' + x.filterData;
      });
    }

    return toSend;
  }

  function getFiltersToSend(tabFilters: string[], advancedFilters: AdvancedFilter[]) {
    const toSend: GetProductParameters = {};

    if (tabFilters && tabFilters?.length > 0) toSend.isActive = tabFilters[0] === 'Actif' ? true : undefined;

    if (advancedFilters.length > 0) {
      advancedFilters.map((x) => {
        if (x.filterType === 'productType') toSend.productTypeId = x.filterData?.value;
        if (x.filterType === 'labelAndReference') {
          toSend.externalReference = x.filterData;
          toSend.label = x.filterData;
        }
      });
    }

    return toSend;
  }

  // useEffect(() => {
  //   fetchItems(1, perPage); // instead called by filtersHeader loading
  // }, []);

  const handlePageChange = (pageNumber: number) => {
    fetchItems(pageNumber, perPage);
  };

  const handlePerRowsChange = (newPerPage: any) => {
    setPerPage(newPerPage);
    fetchItems(1, newPerPage);
  };

  const onSort = (data: any, newSortProduct: any) => {
    if (data.id == sortColumn) {
      if (sortDirection == 'asc') {
        newSortProduct = 'desc';
      } else {
        newSortProduct = 'asc';
      }
    } else {
      newSortProduct = 'asc';
    }
    fetchItems(1, perPage, data.id, newSortProduct);
  };

  function handleSetFiltersAdvanced(newFilters: AdvancedFilter[]) {
    setFiltersAdvanced(newFilters);
    fetchItems(currentPage, perPage, sortColumn, sortDirection, filtersTab, newFilters);
  }

  const seeDetails = (row: Product) => {
    // TODO INTEGRATION : gestion popup si dans sous-fenetre ?
    const url = 'products';
    return `/${url}/${row.id}/update`;
  };

  const columns: any[] = [
    {
      name: t('common.label'),
      selector: (row: Product) => row.label,
      cell: (row: Product) => (
        <Link to={seeDetails(row)}>
          <div className='firstColumn' data-tag='allowRowEvents'>
            <strong className='caption2medium' data-tag='allowRowEvents'>
              {row.label}
            </strong>
          </div>
        </Link>
      ),
      sortable: true,
      right: false,
      id: 'label',
      grow: 1,
    },
    {
      name: t('common.reference'),
      selector: (row: Product) => `${row.externalReference}`,
      cell: (row: Product) => (
        <div className='firstColumn' data-tag='allowRowEvents'>
          <div data-tag='allowRowEvents'>
            <strong className='caption2medium' data-tag='allowRowEvents'>
              {row.externalReference}
            </strong>
          </div>
        </div>
      ),
      sortable: true,
      right: false,
      id: 'externalReference',
      grow: 1,
    },
    {
      name: t('common.type'),
      selector: (row: Product) => row.productType,
      cell: (row: Product) => (
        <div className='produit-column-label' data-tag='allowRowEvents'>
          <strong className='caption1' data-tag='allowRowEvents'>
            {row.productType}
          </strong>
        </div>
      ),
      sortable: false,
      right: false,
      id: 'productType',
    },
    {
      name: t('common.unit'),
      selector: (row: Product) => row.unit,
      cell: (row: Product) => (
        <div className='produit-column-label' data-tag='allowRowEvents'>
          <strong className='caption1' data-tag='allowRowEvents'>
            {t('unit.' + row.unit)}
          </strong>
        </div>
      ),
      sortable: true,
      right: false,
      id: 'unit',
    },
    {
      name: t('common.active'),
      selector: (row: Product) => row.isActive,
      cell: (row: Product) => (
        <Switch onChange={onChangeSwitch} checked={isActive[row.id] ?? false} id={row.id} onColor={'#2a85ff'} />
      ),
      sortable: true,
      right: false,
      id: 'isActive',
    },
  ];

  const onChangeSwitch = (checked: boolean, event: any, id: string) => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');

    const url = orgid + '/product/change-activation/' + id;

    axios
      .get(Config.getApiExtranetUrl(url), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (res: any) => {
        addToast(t('common.updatedProduct'), ToastTypes.success);
        setIsActive((prevState) => ({ ...prevState, [id]: checked }));
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  };

  function handleSetFiltersStatus(newFilters: string[]) {
    setFiltersTab(newFilters);
    fetchItems(currentPage, perPage, sortColumn, sortDirection, newFilters, filtersAdvanced);
  }

  return (
    <>
      <FilterHeader
        title={''}
        allowQuickSearch={true}
        allowAdvancedSearch={true}
        advancedSearchOptions={['labelAndReference', 'productType']}
        showAddNew={true}
        addNewText={t('common.newProduct')}
        addNew={() => {
          history('./add');
        }}
        forcedFilters={props.forcedFilters}
        quickSearchFilterTypes={['labelAndReference']}
        advancedSearchFilterChanged={handleSetFiltersAdvanced}
      />

      <TabFilter
        tabArray={[
          { tabLabel: 'Tous', tabLabelTranslate: t('common.all') },
          { tabLabel: 'Actif', tabLabelTranslate: t('common.active') },
        ]}
        parent={'Product'}
        filterSet={handleSetFiltersStatus}
        defaultTab={'Tous'}
      />

      {isLoading ? (
        <div>{t('common.loading')}</div>
      ) : (
        <DataTable
          noHeader={true}
          customStyles={TableStyles}
          columns={columns}
          onRowClicked={(row) => history(seeDetails(row))}
          highlightOnHover
          pointerOnHover
          data={filteredItems}
          noDataComponent={<div className='noResults'>{t('common.noProduct')}</div>}
          defaultSortAsc={sortDirection == 'asc'}
          defaultSortFieldId={sortColumn}
          onSort={(data, newSortDirection) => onSort(data, newSortDirection)}
          sortServer
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          paginationDefaultPage={currentPage}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationPerPage={perPage}
          paginationRowsPerPageOptions={[5, 10, 20, 50, 100, 200]}
          paginationComponentOptions={{
            rowsPerPageText: t('common.perPage'),
            rangeSeparatorText: t('common.onPage'),
            noRowsPerPage: false,
            selectAllRowsItem: false,
            selectAllRowsItemText: 'All',
          }}
        />
      )}
    </>
  );
});

ListProducts.displayName = 'ListProducts';
export default ListProducts;
