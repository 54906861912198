import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormRow } from '../../../stories/dune/atoms/FormRow';
import { Button } from '../../../stories/dune/atoms/Button';
import '../SubMenu.scss';

interface SubMenuVehicleProps {
  tab: string;
  updateTab: any;
}

export const SubMenuVehicle = React.memo((props: SubMenuVehicleProps) => {
  const { t } = useTranslation();

  return (
    <div className='submenu'>
      <FormRow>
        <Button
          label={t('common.generalInformations')}
          size='wide'
          style={props.tab === 'general' ? 'primary' : 'white'}
          onClick={() => props.updateTab('general')}
        />
        <Button
          label={t('common.contacts')}
          size='wide'
          style={props.tab === 'contact' ? 'primary' : 'white'}
          onClick={() => props.updateTab('contact')}
        />
        {/* <Button
          label={t('menu.orders')}
          size="wide"
          style={props.tab === 'order' ? 'primary' : 'white'}
          onClick={() => props.updateTab('order')}
        /> */}

        <Button
          label={t('common.trips')}
          size='wide'
          style={props.tab === 'trip' ? 'primary' : 'white'}
          onClick={() => props.updateTab('trip')}
        />

        <Button
          label={t('common.documents')}
          size='wide'
          style={props.tab === 'documents' ? 'primary' : 'white'}
          onClick={() => props.updateTab('documents')}
        />
      </FormRow>
    </div>
  );
});

SubMenuVehicle.displayName = 'SubMenuVehicle';
