import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import TableStyles from '../../../styles/tableStyles';
import axios from 'axios';
import Config from '../../../Config';
import '../../Zones.scss';
import '../../../styles/table.scss';
import { Link, useNavigate } from 'react-router-dom';
import { ToastTypes } from '../../../models/ToastTypes';
import { popupContentStyle, popupOverlayStyle } from '../../../styles/popupContentStyle';
import Popup from 'reactjs-popup';
import Zone, { ZoneSave } from '../../../models/Zone';
import { useTranslation } from 'react-i18next';
import AddChildZone from './AddChildZone';
import { Button } from '../../../stories/dune/atoms/Button';
import styles from '../../lists/filterHeader.module.scss';
import { Icon } from '../../../stories/dune/atoms/Icon';
import PopupContainer from '../../../stories/dune/organisms/PopupContainer';
import useToast from '../../../hooks/use-toast';

interface ChildZoneListProps {
  zn?: Zone;
  onChildSelected: (zone: Zone) => void;
}

const ChildZoneList = React.memo((props: ChildZoneListProps) => {
  const { t } = useTranslation();
  const { addToast, addFixedToast } = useToast();
  const history = useNavigate();

  const [sortColumn, setSortColumn] = useState('label');
  const [sortDirection, setSortDirection] = useState('asc');

  const [zoneInfo, setZoneInfo] = useState<Zone>(props.zn ?? new Zone());

  const [isOpenConfirmDeleteChildZone, setIsOpenConfirmDeleteChildZone] = useState(false);
  const [currentChildZoneToDelete, setCurrentChildZoneToDelete] = useState<{ label: string; id: string }>();
  const [isOpenAddChildZone, setIsOpenAddChildZone] = useState(false);

  const onSuccessAddChildZone = (newZone: any) => {
    setIsOpenAddChildZone(false);

    setZoneInfo((prevValue) => {
      return { ...prevValue, childZones: [...(prevValue?.childZones ?? []), newZone] };
    });
    addToast(t('common.zoneUpdated'), ToastTypes.success);
  };

  const onDeleteChildZoneOk = (childZone: { label: string; id: string } | undefined) => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');

    const baseUrl = '/zone/edit/' + zoneInfo?.id;
    const url = orgid + `${baseUrl}`;

    const dataToSend: ZoneSave = {
      ...zoneInfo,
      products: zoneInfo?.products?.map((p) => p.id ?? '') ?? [],
      childZonesId: zoneInfo?.childZones?.filter((z) => z.id != childZone?.id).map((z) => z.id),
    };

    axios
      .post(Config.getApiExtranetUrl(url), dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (zoneInfo != undefined) {
          setZoneInfo((prevValue) => {
            return { ...prevValue, childZones: prevValue.childZones?.filter((z) => z.id != childZone?.id) };
          });
        }
        setIsOpenConfirmDeleteChildZone(false);
        addToast(t('common.zoneUpdated'), ToastTypes.success);
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  };
  const seeDetails = (row: Zone) => {
    // TODO INTEGRATION : gestion popup si dans sous-fenetre ?
    const url = 'zones';

    return `/${url}/${row.id}/update`;
  };

  function openChild(zone: Zone) {
    history(seeDetails(zone));
    props.onChildSelected(zone);
  }

  const columns: any[] = [
    // {
    //   name: 'Reference',
    //   selector: (row: Zone) => row.externalReference,
    //   cell: (row: Zone) => <div className="firstColumn">{row.externalReference}</div>,
    //   sortable: true,
    //   right: false,
    //   id: 'externalReference',
    // },
    {
      name: t('common.label'),
      selector: (row: Zone) => row.label,
      cell: (row: Zone) => (
        <Link to={seeDetails(row)}>
          <div className='zone-column-label' data-tag='allowRowEvents'>
            <strong className='caption1' data-tag='allowRowEvents'>
              {row.label}
            </strong>
          </div>
        </Link>
      ),
      sortable: true,
      right: false,
      id: 'label',
    },
    {
      name: '',
      sortable: false,
      cell: (row: any) => (
        <div
          className='column-last'
          onClick={(e: any) => {
            setCurrentChildZoneToDelete(row);
            setIsOpenConfirmDeleteChildZone(true);
          }}
          data-tag='allowRowEvents'
        >
          <Icon icon={'close'} data-tag='allowRowEvents' />
        </div>
      ),
      button: true,
      right: true,
      id: 'remove',
      minWidth: '3%',
      grow: 1,
    },
  ];

  useEffect(() => {
    // fetchZones(1, perPage);
  }, []);

  return (
    <div>
      {/* <Header selectedPage={t('menu.areas')}></Header> */}

      <div className={styles.recherche} style={{ marginBottom: 30 }}>
        <div className={styles.property1designActuelle}>
          <div className={styles.searchBoxParent} style={{ flexFlow: 'row-reverse' }}>
            <Button iconLeft='add' label={t('common.addChildZone')} onClick={() => setIsOpenAddChildZone(true)} />
          </div>
        </div>
      </div>
      <DataTable
        className='table'
        noHeader={true}
        customStyles={TableStyles}
        columns={columns}
        onRowClicked={(row) => openChild(row)}
        highlightOnHover
        pointerOnHover
        data={zoneInfo.childZones ?? []}
        noDataComponent={<div className='noResults'>{'Aucune sous-zone'}</div>}
        defaultSortAsc={sortDirection == 'asc'}
        defaultSortFieldId={sortColumn}
        paginationRowsPerPageOptions={[5, 10, 20, 50, 100, 200]}
        paginationComponentOptions={{
          rowsPerPageText: t('common.perPage'),
          rangeSeparatorText: t('common.onPage'),
          noRowsPerPage: false,
          selectAllRowsItem: false,
          selectAllRowsItemText: 'All',
        }}
      />

      <Popup
        open={isOpenAddChildZone}
        closeOnDocumentClick={false}
        closeOnEscape={true}
        onClose={() => setIsOpenAddChildZone(false)}
        className='popup'
        position={'top center'}
        contentStyle={popupContentStyle}
        overlayStyle={popupOverlayStyle}
      >
        <AddChildZone
          currentZone={zoneInfo}
          childZones={zoneInfo?.childZones}
          onsuccess={onSuccessAddChildZone}
          ClosePopup={() => setIsOpenAddChildZone(false)}
        />
      </Popup>
      {isOpenConfirmDeleteChildZone && (
        <PopupContainer
          message={`${t('common.confirmDelete')} '${currentChildZoneToDelete?.label}' ?`}
          onCancel={function (): void {
            setIsOpenConfirmDeleteChildZone(false);
          }}
          onConfirm={function (): void {
            setIsOpenConfirmDeleteChildZone(false);
            if (currentChildZoneToDelete) onDeleteChildZoneOk(currentChildZoneToDelete);
          }}
          confirmLabel={t('common.yes')}
          confirmIcon='delete'
          cancelLabel={t('common.no')}
        />
      )}
    </div>
  );
});

ChildZoneList.displayName = 'ChildZoneList';
export default ChildZoneList;
