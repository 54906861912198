import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Menu.scss';
import { ReactComponent as Logout } from '../assets/svg/power.svg';
import { ReactComponent as Logo } from '../assets/svg/dune-hover.svg';
import { ReactComponent as LogoNoName } from '../assets/svg/dune.svg';
import { ReactComponent as SvgPlus } from '../assets/svg/plus.svg';
import { ReactComponent as SvgFlash } from '../assets/svg/flash.svg';
import { ReactComponent as SvgCash } from '../assets/svg/cash.svg';
import { ReactComponent as SvgSite } from '../assets/svg/site.svg';
import { ReactComponent as SvgData } from '../assets/svg/data.svg';
import { ReactComponent as SvgSettings } from '../assets/svg/settings.svg';
import Site from '../assets/site.png';
import Settings from '../assets/settings.png';
import Data from '../assets/data.png';
import Cash from '../assets/cash.png';

import Roles from '../utils/Roles';
import { Colors } from '../utils/Colors';
import Toast from '../models/Toast';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ToastTypes } from '../models/ToastTypes';
import eventBus from '../utils/EventBus';
import { useTranslation } from 'react-i18next';
import Lang from './Lang';
import SelectWorkspace from './SelectWorkspace';

export interface MenuProps {
  selectedPage: string;
}

interface MenuInfo {
  type?: MenuType;
  label: string;
  icon?: string;
  link: string;
  subMenu?: MenuInfo[];
  add?: {
    link: string;
  };
  hide?: boolean;
}

const schema = yup.object().shape({
  lastname: yup.string().required('Le nom est obligatoire'),
  firstname: yup.string().required('Le prénom est obligatoire'),
  password: yup
    .string()
    .required('Le mot de passe est obligatoire')
    .test('len', 'Le mot de passe doit faire 8 caractères minimum', (val) => val != null && val?.length >= 8),
  confirmPassword: yup
    .string()
    .required('La confirmation du mot de passe est obligatoire')
    .oneOf([yup.ref('password'), null], 'Les mots de passe doivent être identiques'),
});

const MenuIcon = (props: any) => {
  const ICON_SIZE = 25;
  switch (props.icon) {
    case 'cash':
      return <img src={Cash} height={ICON_SIZE} />;

    // return <SvgCash className="icon" width={ICON_SIZE} height={ICON_SIZE} fill={props.fill} />;
    case 'data':
      return <img src={Data} width={ICON_SIZE} />;

    // return <SvgSite className="icon" width={ICON_SIZE} height={ICON_SIZE} fill={props.fill} />;
    case 'site':
      return <img src={Site} width={ICON_SIZE} />;
    case 'settings':
      return <img src={Settings} width={ICON_SIZE} />;

    // return <SvgSettings className="icon" width={ICON_SIZE} height={ICON_SIZE} fill={props.fill} />;
    default:
      return <SvgFlash className='icon' width={ICON_SIZE} height={ICON_SIZE} fill={props.fill} />;
  }
};

enum MenuType {
  management = 'management',
  trips = 'trips',
  mySite = 'mySite',
  data = 'data',
  settings = 'settings',
}

const Menu = React.memo((props: MenuProps) => {
  const history = useNavigate();

  const { t } = useTranslation();

  const [toasts, setToast] = useState<Toast[]>([]);
  // const [totalBlToSign, setTotalBlToSign] = useState(0);
  // const [confirmPassword, setConfirmPassword] = useState('');
  const [hiddenCollapseSite, setHiddenCollapseSite] = useState(true);
  const [hiddenCollapseAccess, setHiddenCollapseAccess] = useState(true);
  const [hiddenCollapseData, setHiddenCollapseData] = useState(true);
  const [collaspedMenu, setCollaspedMenu] = useState(true);

  const [isOpenRightMenu, setIsOpenRightMenu] = useState(false);
  const [currentMenuInfo, setCurrentMenuInfo] = useState<any>({});

  const [hideQuotations, setHideQuotations] = useState(
    [
      'ca4f0ffc-cc7e-11ec-9d49-d7983596ae10', // Carlac Prod
      'ebd53fa5-67e3-46a4-92ec-1261762ec467', // Carlac staging
      '78d47080-9893-4a40-bfac-635a306b0215', // GDC prod
      'd9562444-6721-49c3-8b2d-4f3aa1acc38f', // Etavaux prod
    ].findIndex((x) => x === localStorage.getItem('orgid')) !== -1
      ? true
      : false,
  ); // TODO TEMP TO REMOVE WHEN MANAGED IN ROLES

  const menuData: MenuInfo[] = [
    {
      type: MenuType.management,
      label: 'menu.management',
      icon: 'cash',
      link: 'management',
      hide: hideQuotations,
      add: {
        link: 'contract/add/customer',
      },
      subMenu: [
        {
          label: 'common.quotations',
          link: 'quotations',
        },
        {
          label: 'common.contracts',
          link: 'contracts',
        },
        {
          label: 'menu.orders',
          link: 'orders',
        },
        {
          label: 'common.invoicing',
          link: 'invoicing/toInvoice',
        },
        {
          label: 'common.wasteAcceptationRequestsAndCertificates',
          link: 'wasteRequests',
        },
      ],
    },
    {
      type: MenuType.trips,
      label: 'menu.trips',
      icon: 'trip',
      link: 'trips',
      add: {
        link: 'trips/add',
      },
    },
    {
      type: MenuType.mySite,
      label: 'menu.mySite',
      icon: 'site',
      link: 'zones',
      subMenu: [
        // {
        //   label: 'menu.trips',
        //   link: 'trips',
        //   add: {
        //     link: 'trips/add',
        //   },
        // },
        {
          label: 'menu.authorizations',
          link: 'authorizedVehicles',
          add: {
            link: 'authorizedVehicles/add',
          },
        },
        {
          label: 'menu.areas',
          link: 'zones',
        },
        {
          label: 'menu.equipments',
          link: 'manageequipments',
        },
      ],
    },

    {
      type: MenuType.data,
      label: 'menu.data',
      icon: 'data',
      link: 'customers',
      subMenu: [
        {
          label: 'menu.orders',
          link: 'orders',
          hide: !hideQuotations,
        },
        {
          label: 'menu.customers',
          link: 'customers',
        },
        {
          label: 'menu.buildingSites',
          link: 'buildingSites',
        },

        {
          label: 'menu.carriers',
          link: 'carriers',
        },
        {
          label: 'menu.vehicles',
          link: 'vehicles',
        },
        {
          label: 'menu.products',
          link: 'products',
        },
      ],
    },
    // {
    //   type: MenuType.settings,
    //   label: 'menu.settings',
    //   icon: 'settings',
    //   link: 'settings',
    //   subMenu: [{ label: 'menu.company', link: 'company' }],
    // },
  ];

  const {
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });
  // end create account

  const goHome = () => {
    history('/trips');
  };

  const to = (route: string) => {
    return '/' + route;
  };

  const logout = () => {
    localStorage.clear();
    history('/');
  };

  const addToast = (message: string, type: ToastTypes) => {
    setToast([...toasts, new Toast(message, '', type)]);
  };

  const toggleSubMenu = (menuInfo: any) => {
    if (menuInfo?.label == currentMenuInfo?.label) {
      setIsOpenRightMenu(false);
      setCurrentMenuInfo(undefined);
    } else {
      setIsOpenRightMenu(true);
      setCurrentMenuInfo(menuInfo);
    }
  };

  const manageCollapse = () => {
    let selectedMenuData = undefined;
    switch (props.selectedPage) {
      case 'quotation':
      case 'orders':
      case 'invoices':
        selectedMenuData = menuData.find((menuInfo) => menuInfo.type == MenuType.management);
        break;
      case 'trips':
      case 'authorizedvehicles':
      case 'zones':
      case 'equipment':
        selectedMenuData = menuData.find((menuInfo) => menuInfo.type == MenuType.mySite);
        break;
      case 'customers':
      case 'buildingSites':
      case 'carriers':
      case 'vehicles':
      case 'products':
        selectedMenuData = menuData.find((menuInfo) => menuInfo.type == MenuType.data);
        break;
      case 'company':
        selectedMenuData = menuData.find((menuInfo) => menuInfo.type == MenuType.settings);
        break;
      default:
        break;
    }
    if (selectedMenuData) {
      toggleSubMenu(selectedMenuData);
    }
  };

  useEffect(() => {
    // manageCollapse();
    eventBus.on('new_alert', () => {
      addToast('Nouvelle alerte', ToastTypes.success);
    });
    window.scrollTo(0, 0);
  }, []);

  const isItemSelected = (menuInfo: MenuInfo) => {
    const isFirstLevelSelected = menuInfo.link === props.selectedPage || props.selectedPage.includes(menuInfo.link);
    let isChildrenSelected = false;
    if (menuInfo.subMenu) {
      isChildrenSelected = menuInfo.subMenu.some((subMenu: any) => {
        return !subMenu.hide && (subMenu.link === props.selectedPage || props.selectedPage.includes(subMenu.link));
      });
    }
    return isFirstLevelSelected || isChildrenSelected;
  };

  return (
    <>
      <div className={`background-menu ${collaspedMenu ? 'collapsed' : ''}`}></div>
      <div className={'left menu'}>
        <div className='menu-content'>
          <div className={`menu-left ${collaspedMenu ? 'collapsed' : ''}`}>
            <div className='menu-top'>
              <div
                className='logoName'
                onClick={() => {
                  setCollaspedMenu(!collaspedMenu);
                }}
              >
                {collaspedMenu ? <LogoNoName /> : <Logo />}
              </div>

              {!collaspedMenu && (
                <div className='selectWorkspace'>
                  <SelectWorkspace parent='menu' />
                </div>
              )}

              {menuData.map((menuInfo, index) => {
                return !menuInfo.hide ? (
                  menuInfo.subMenu ? (
                    <div
                      className={isItemSelected(menuInfo) ? 'rowSelected' : 'rowNotSelected'}
                      onClick={() => toggleSubMenu(menuInfo)}
                      key={index}
                    >
                      <div className='links'>
                        <div className={isItemSelected(menuInfo) ? 'linkSelected' : 'link'}>
                          <span className='icon'>
                            <MenuIcon icon={menuInfo.icon} fill={isItemSelected(menuInfo) ? '#90A0B7' : '#90A0B7'} />
                          </span>
                          {!collaspedMenu && (
                            <span className='caption1' style={{ marginLeft: 10 }}>
                              {t(menuInfo.label)}
                            </span>
                          )}
                        </div>
                        {!collaspedMenu && menuInfo.add && (
                          <Link to={to(menuInfo.add.link)} className={'add-voyage rowSelected'}>
                            <SvgPlus className='svg'></SvgPlus>
                          </Link>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className={isItemSelected(menuInfo) ? 'rowSelected' : 'rowNotSelected'} key={index}>
                      <div className='links'>
                        <Link to={to(menuInfo.link)}>
                          <div className={isItemSelected(menuInfo) ? 'linkSelected' : 'link'}>
                            <span className='icon'>
                              <MenuIcon
                                icon={menuInfo.icon}
                                fill={isItemSelected(menuInfo) ? Colors.primary : '#90A0B7'}
                              />
                            </span>
                            {!collaspedMenu && (
                              <span className='caption1' style={{ marginLeft: 10 }}>
                                {t(menuInfo.label)}
                              </span>
                            )}
                          </div>
                        </Link>
                        {!collaspedMenu && menuInfo.add && (
                          <Link
                            to={to(menuInfo.add.link)}
                            className={
                              isItemSelected(menuInfo) ? 'add-voyage rowSelected' : ' add-voyage rowNotSelected'
                            }
                          >
                            <SvgPlus className='svg'></SvgPlus>
                          </Link>
                        )}
                      </div>
                    </div>
                  )
                ) : null;
              })}
            </div>

            <div className='menu-bottom'>
              <div className='rowNotSelected'>
                <div className='block'>
                  <div className='caption1 userEmail'>
                    <Link to={to('account')}>
                      <div className={'link'}>
                        {!collaspedMenu && <span className='caption1'>{Roles.getEmail()}</span>}
                      </div>
                    </Link>
                  </div>
                  <div className='links' onClick={() => logout()} style={{ paddingTop: 5 }}>
                    <div className={'link'}>
                      <span className='icon'>
                        <Logout className='icon' width='25' height='20' fill={'#90A0B7'}></Logout>
                      </span>
                      {!collaspedMenu && (
                        <span className='caption1' style={{ marginLeft: 10 }}>
                          {t('menu.logout')}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <Lang />

              <div className='caption1 versionNumber'>
                <Link to={to('changelog')}>
                  <div className={'link center'}>
                    <span className='caption1'>
                      {(process.env.REACT_APP_VERSION ?? null) !== null ? `v${process.env.REACT_APP_VERSION}` : ''}
                    </span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          {isOpenRightMenu && (
            <div className={`menu-right ${collaspedMenu ? 'collapsed' : ''}`}>
              {currentMenuInfo.subMenu &&
                currentMenuInfo.subMenu.map((subMenu: any, index: number) => {
                  return !subMenu.hide ? (
                    <div className={isItemSelected(subMenu) ? 'rowSelected' : 'rowNotSelected'} key={index}>
                      <div className='links'>
                        <Link to={to(subMenu.link)}>
                          <div className={isItemSelected(subMenu) ? 'linkSelected' : 'link'}>
                            <span className='caption1' style={{ marginLeft: 10 }}>
                              {t(subMenu.label)}
                            </span>
                          </div>
                        </Link>
                        {subMenu.add && (
                          <Link to={to(subMenu.add.link)} className={'add-voyage rowSelected'}>
                            <SvgPlus className='svg'></SvgPlus>
                          </Link>
                        )}
                      </div>
                    </div>
                  ) : (
                    <></>
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </>
  );
});

Menu.displayName = 'Menu';
export default Menu;
