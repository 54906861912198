import React from 'react';
import './TabHeader.scss';
// import { FaBell, FaKey } from 'react-icons/fa';
import { ReactComponent as SvgPlus } from '../assets/svg/plus.svg';
import { ReactComponent as SvgFilter } from '../assets/svg/filter.svg';
import { useTranslation } from 'react-i18next';

export interface TabHeaderProps {
  ClickAdd: (val: boolean) => void;
  ClickExport: string;
  ClickImport: string;
  ClickFilter: string;
  label: string;
  labelButtonAdd: string;
  VisibleExport: boolean;
  VisibleImport: boolean;
  VisibleAdd: boolean;
  VisibleFilters: boolean;
}

const TabHeader = React.memo((props: TabHeaderProps) => {
  const { t } = useTranslation();

  const onClickAdd = () => {
    props.ClickAdd(true);
  };

  const onClickExporter = () => {
    // history(props.ClickExport);
  };

  const onClickImporter = () => {
    // history(props.ClickImport);
  };

  const onClickFiltres = () => {
    // history(props.ClickFiltres);
  };

  return (
    <div className='tabHeader'>
      {/* <div
        className="validateBtnn"
        style={{ padding: 10, marginTop: 0 }}
        // onClick={() => setxxx(product)}
      >
        {' '}
        <h6>
          <FaKey />
        </h6>{' '}
      </div> */}
      <div className='tabHeaderTitle title1SemiBold'>{props.label}</div>
      <div className='tabHeaderBtn'>
        {props.VisibleFilters ? (
          <div className='filterBtn' onClick={onClickFiltres}>
            <SvgFilter className='svgfilter' />
            <div className='base2'>{t('common.filters')}</div>
          </div>
        ) : null}
        {props.VisibleImport ? (
          <div className='importExportBtn' onClick={onClickImporter}>
            <div className='base2'>{t('common.import')}</div>
          </div>
        ) : null}
        {props.VisibleExport ? (
          <div className='importExportBtn' onClick={onClickExporter}>
            <div className='base2'>{t('common.export')}</div>
          </div>
        ) : null}
        {props.VisibleAdd ? (
          <div className='addBtn' onClick={onClickAdd}>
            <SvgPlus className='svgPlus' />
            <div className='base2'>{props.labelButtonAdd}</div>
          </div>
        ) : null}
      </div>
    </div>
  );
});

TabHeader.displayName = 'TabHeader';
export default TabHeader;
