import React from 'react';
import { useTranslation } from 'react-i18next';
import ContractHistories from '../../lists/Quotations/QuotationHistories';
import { GetQuotation as GetContract } from '../../../models/Quotation';

interface HistContractProps {
  contractData: GetContract | undefined;
}

const HistContract = React.memo((props: HistContractProps) => {
  const { t } = useTranslation();

  return <>{props.contractData && <ContractHistories quotationData={props.contractData} />}</>;
});

HistContract.displayName = 'HistContract';
export default HistContract;
