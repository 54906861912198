// import Roles from './utils/Roles';

export default class Config {
  static getApiExtranetUrl(path = '') {
    return process.env.REACT_APP_API_URL + 'extranet/' + path;
  }

  static getApiUrl() {
    return process.env.REACT_APP_API_URL;
  }

  static getUrl(path: string) {
    if (path.includes('?')) {
      return (
        // this.getApiExtranetUrl() + Roles.getCurrentWorkspaceUuid() + '/' + path + '&d=' + new Date().toISOString()
        // 'https://api-staging.dunemanager.com/core' + '/' + path + '&d=' + new Date().toISOString()
        process.env.REACT_APP_API_URL + 'core/' + path
      );
    } else {
      return (
        // this.getApiExtranetUrl() + Roles.getCurrentWorkspaceUuid() + '/' + path + '?d=' + new Date().toISOString()
        // 'https://api-staging.dunemanager.com/core' + '/' + path + '&d=' + new Date().toISOString()
        process.env.REACT_APP_API_URL + 'core' + '/' + path
      );
    }
  }
}
