import React from 'react';
import { useTranslation } from 'react-i18next';
import Menu from '../../Menu';
import { Head } from '../../../stories/dune/molecules/Head';
import { useNavigate } from 'react-router-dom';
import ListAuthorizedVehicles from '../../lists/AuthorizedVehicles/ListAuthorizedVehicles';

const AuthorizedVehicles = React.memo(() => {
  const { t } = useTranslation();

  const history = useNavigate();

  return (
    <div className='main-container'>
      <Menu selectedPage='authorizedVehicles' />
      <div className='right detail-container'>
        <Head
          title={t('common.authorizedVehicles')}
          back={false}
          OnNotificationClick={function (): void {
            throw new Error('Function not implemented.');
          }}
          OnAutorisationClick={function (): void {
            throw new Error('Function not implemented.');
          }}
          Back={function (): void {
            // history('/quotations');
          }}
        />
        <ListAuthorizedVehicles canAddNew={true} />
      </div>
    </div>
  );
});

AuthorizedVehicles.displayName = 'AuthorizedVehicles';
export default AuthorizedVehicles;
