import React, { useState } from 'react';
import './BottomBar.scss';
import { ReactComponent as SvgLink } from '../assets/svg/link.svg';
import { ReactComponent as SvgCheck } from '../assets/svg/check.svg';
import { ReactComponent as SvgSave } from '../assets/svg/save.svg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FixBar } from '../stories/dune/molecules/FixBar';

export interface BottomBarProps {
  disabledSxdBtn: boolean;
  disabledSaveBtn?: boolean;
  connectedSxD: boolean;
  saveBottomSuccess: boolean;
  page: string;
  ClickSave: (val: any) => void;
}

const BottomBar = React.memo((props: BottomBarProps) => {
  const { t } = useTranslation();
  const [hiddenInput, sethiddenInput] = useState(true);

  const history = useNavigate();

  const openInput = () => {
    sethiddenInput(false);
  };

  const onClickCancel = () => {
    history('/' + props.page);
  };

  const onClickSave = () => {
    props.ClickSave(true);
  };

  return (
    <FixBar>
      <div className=''>
        {/* <div className="bottomBar__left">
        <SvgSearch className="svgSearch" onClick={openInput} />
        <input className="searchInput" type="text" placeholder="Recherche" hidden={hiddenInput} />
      </div> */}
        <div className='bottomBar__right'>
          <div className='cancelBtn' onClick={onClickCancel}>
            <div className='base2'>{t('common.cancel')}</div>
          </div>
          {!props.disabledSxdBtn ? (
            <div className={props.connectedSxD ? 'connectedSxdBtn' : 'notConnectedSxdBtn'}>
              {props.connectedSxD ? <SvgCheck className='svgConnexionSxd' /> : <SvgLink className='svgConnexionSxd' />}
              {props.connectedSxD ? (
                <div className='base2'>{t('common.sxdConnected')}</div>
              ) : (
                <div className='base2'>{t('common.sxdConnect')}</div>
              )}
            </div>
          ) : (
            ''
          )}

          {(props.disabledSaveBtn ?? false) === false ? (
            <div className={props.saveBottomSuccess ? 'saveBtnSuccess' : 'saveBtn'} onClick={onClickSave}>
              {props.saveBottomSuccess ? <SvgSave className='svgSave' /> : <SvgSave className='svgSave' />}
              {props.saveBottomSuccess ? (
                <div className='base2'>{t('common.saved')}</div>
              ) : (
                <div className='base2'>{t('common.save')}</div>
              )}
            </div>
          ) : (
            <div className={'saveBtnDisable'}>
              {props.saveBottomSuccess ? <SvgSave className='svgSave' /> : <SvgSave className='svgSave' />}
              {props.saveBottomSuccess ? (
                <div className='base2'>{t('common.saved')}</div>
              ) : (
                <div className='base2'>{t('common.save')}</div>
              )}
            </div>
          )}
        </div>
      </div>
    </FixBar>
  );
});

BottomBar.displayName = 'BottomBar';
export default BottomBar;
