import { createSlice, createAsyncThunk, Slice } from '@reduxjs/toolkit';
import { AdditionalDocType } from '../models/AdditionalDocType';
import axios from 'axios';
import Config from '../Config';
import { VatRate } from '../models/VatRate';
import { CustomerType } from '../models/CustomerType';

export interface StaticDataState {
  additionalDocTypes: AdditionalDocType[];
  vatRates: VatRate[];
}
const sliceInitialState: StaticDataState = {
  additionalDocTypes: [],
  vatRates: [],
};

// Used for static data that will not change from one workspace to another
const staticDataSlice: Slice<StaticDataState> = createSlice({
  name: 'staticData',
  initialState: sliceInitialState,
  reducers: {
    reset: () => sliceInitialState,
  },
  extraReducers: (builder) => {
    builder.addCase(loadStaticData.fulfilled, (state, action) => {
      state.additionalDocTypes = action.payload.additionalDocTypes;
      state.vatRates = action.payload.vatRates;
    });
  },
});

// Define an async thunk action
export const loadStaticData = createAsyncThunk('staticData/loadStaticData', async () => {
  const additionalDocTypes = await fetchDocTypes();
  const vatRates = await fetchVatRates();
  return { additionalDocTypes: additionalDocTypes, vatRates: vatRates };
});

async function fetchDocTypes(): Promise<AdditionalDocType[]> {
  const token = localStorage.getItem('token');
  const orgid = localStorage.getItem('orgid');

  if (!token || !orgid) {
    return [];
  }

  const baseUrl = '/additional-doc-type/get?onlyActive=true&page=1&limit=1000';
  const url = orgid + `${baseUrl}`;

  // const dataToSend: SearchTT = {
  //   genericAdditionalDocType: (searchParent ?? null) === null ? undefined : (searchParent as GenericAdditionalDocType[]),
  // };

  try {
    const res = await axios.get(
      Config.getApiExtranetUrl(url),
      /* stringUtils.formatFieldsForPost(dataToSend),*/ {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return res.data.content.items;
  } catch (error) {
    console.log('error fetchDocTypes', error);
    return [];
  }
}

async function fetchVatRates(): Promise<VatRate[]> {
  const token = localStorage.getItem('token');
  const orgid = localStorage.getItem('orgid');

  if (!token || !orgid) {
    return [];
  }

  const baseUrl = '/vat-rate/get';
  const url = orgid + `${baseUrl}`;

  // const dataToSend: SearchTT = {
  //   genericAdditionalDocType: (searchParent ?? null) === null ? undefined : (searchParent as GenericAdditionalDocType[]),
  // };

  try {
    const res = await axios.get(
      Config.getApiExtranetUrl(url),
      /* stringUtils.formatFieldsForPost(dataToSend),*/ {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return res.data.content.items;
  } catch (error) {
    console.log('error fetchVatRates', error);
    return [];
  }
}

export const staticDataActions = staticDataSlice.actions;

export default staticDataSlice.reducer;
