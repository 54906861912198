import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CardDetail } from '../../../stories/dune/atoms/CardDetail';
import { FormRow } from '../../../stories/dune/atoms/FormRow';
import { Input } from '../../../stories/dune/atoms/Input';
import { Title } from '../../../stories/dune/atoms/Title';
import BuildDataCustom, { DataCustomItem } from '../../forms/BuildDataCustom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { FixBar } from '../../../stories/dune/molecules/FixBar';
import { Button } from '../../../stories/dune/atoms/Button';
import useToast from '../../../hooks/use-toast';
import axios from 'axios';
import { ToastTypes } from '../../../models/ToastTypes';
import Config from '../../../Config';
import countryList from '../../Countries';
import { Toggle } from '../../../stories/dune/atoms/Toggle';
import Switch from 'react-switch';
import stringUtils from '../../../utils/stringUtils';
import Select, { createFilter } from 'react-select';
import { customStylesSelectReactPopup } from '../../../styles/custom-styles-select-react';
import { GetVehicle, GetVehicleInfo, VehicleInfoSave, VehicleSave } from '../../../models/Vehicle';
import { SelectAxleNumber, SelectAxleNumberOption } from '../../forms/SelectAxleNumber';
import { SelectCarrier, SelectCarrierOption } from '../../forms/SelectCarrier';
import { SelectVehicleType, SelectVehicleTypeOption } from '../../forms/SelectVehicleType';
import moment from 'moment';
import { GlobalParameter } from '../../../models/GlobalParameter';

interface GeneralVehicleProps {
  id?: string;
  vehicleData: GetVehicle | undefined;
  vehicleInfoData: GetVehicleInfo | undefined;

  onCancel: () => void;
  onConfirm: (data: any) => void;

  mode: string;
}

interface IFormInputs {
  registrationPlate?: string;
  trailerPlate?: string;
  vehicleType?: {
    value: string;
    label: string;
  };
  ptacMax?: number;
  maxOfPTAC?: number;
  tareWeight?: number;
  maxWeight?: number;
  dryWeight?: number;
  carrier?: {
    value: string;
    label: string;
  };
  axleNumber?: number;
  sixthAxleAdditionalWeight?: number;
  hasInductionBreak?: boolean;
  inductionBreakAdditionalWeight?: number;
  hasAlternativeMotorSystem?: boolean;
  alternativeMotorSystemAdditionalWeight?: number;
}

const GeneralVehicle = React.memo((props: GeneralVehicleProps) => {
  const { t } = useTranslation();
  const history = useNavigate();
  const { addToast, addFixedToast } = useToast();
  const [isAddingLoading, setIsAddingLoading] = useState(false);

  const [siteTimeZone, setSiteTimeZone] = useState<string>('Europe/Paris');
  useEffect(() => {
    const globalParametersSite: GlobalParameter[] = JSON.parse(localStorage.getItem('globalParameters') ?? '[]');
    setSiteTimeZone(globalParametersSite.find((x) => x.label === 'siteTimeZone')?.value ?? 'Europe/Paris');
  }, []);

  const [saveVehicle, setSaveVehicle] = useState<VehicleSave>();
  const [saveVehicleInfo, setSaveVehicleInfo] = useState<VehicleInfoSave>();

  const [isActive, setIsActive] = useState<boolean>(true);
  const [carrierOption, setCarrierOption] = useState<SelectCarrierOption>();

  const [registrationPlate, setRegistrationPlate] = useState<string>();
  const [trailerPlate, setTrailerPlate] = useState<string>();

  const [ptacMax, setPtacMax] = useState<number>();
  const [maxWeight, setMaxWeight] = useState<number>();
  const [dryWeight, setDryWeight] = useState<number>();
  const [tareWeight, setTareWeight] = useState<string>();
  const [lastTareDate, setLastTareDate] = useState<Date>();
  const [dsdTare, setDsdTare] = useState<string>();

  const [errorMessage, setErrorMessage] = useState('');

  const [vehicleTypeOption, setVehicleTypeOption] = useState<SelectVehicleTypeOption>();

  const [inductionBreakAdditionalWeight, setInductionBreakAdditionalWeight] = useState<number>();
  const [hasInductionBreak, setHasInductionBreak] = useState<boolean>(false);

  const [sixthAxleAdditionalWeight, setSixthAxleAdditionalWeight] = useState<number>();

  const [alternativeMotorSystemAdditionalWeight, setAlternativeMotorSystemAdditionalWeight] = useState<number>();
  const [hasAlternativeMotorSystem, setHasAlternativeMotorSystem] = useState<boolean>(false);

  const [selectedOptionAxleNumber, setSelectedOptionAxleNumber] = useState<SelectAxleNumberOption>();
  const [optionsAxleNumber, setOptionsAxleNumber] = useState<any[]>([]);

  const schema = yup.object().shape({
    registrationPlate: yup
      .string()
      .required(t('common.requiredRegistrationPlate'))
      .max(10, t('common.lengthRegistrationPlate'))
      .matches(/^[a-zA-Z0-9]+$/, { message: t('common.onlyAlphanumeric') }),
    inductionBreakAdditionalWeight: yup
      .number()
      .nullable()
      .notRequired()
      .min(0, t('common.valueBetween') + '0 ' + t('common.and') + ' 0,5')
      .max(0.5, t('common.valueBetween') + '0 ' + t('common.and') + ' 0,5'),
    alternativeMotorSystemAdditionalWeight: yup
      .number()
      .nullable()
      .notRequired()
      .min(0, t('common.valueBetween') + '0 ' + t('common.and') + ' 1')
      .max(1, t('common.valueBetween') + '0 ' + t('common.and') + ' 1'),
    sixthAxleAdditionalWeight: yup
      .number()
      .nullable()
      .notRequired()
      .min(0, t('common.valueBetween') + '0 ' + t('common.and') + ' 1')
      .max(1, t('common.valueBetween') + '0 ' + t('common.and') + ' 1')
      .transform((curr, orig) => (orig === '' ? null : curr)),
    trailerPlate: yup
      .string()
      .nullable()
      .notRequired()
      .matches(/^[a-zA-Z0-9]+$/, { message: t('common.onlyAlphanumeric'), excludeEmptyString: true }),
    vehicleType: yup.object().shape({
      value: yup.string().required(t('common.requiredTypeVehicle')),
      label: yup.string().required(t('common.requiredTypeVehicle')),
    }),
    ptacMax: yup
      .number()
      .min(0, t('common.positiveValuePTAC'))
      .max(yup.ref('maxOfPTAC'), t('common.highValuePTAC'))
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr)),
    tareWeight: yup
      .number()
      .notRequired()
      .min(0, t('common.positiveValueTare'))
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr)),
    dryWeight: yup
      .number()
      .notRequired()
      .min(0, t('common.positiveValueDryWeight'))
      .nullable()
      .max(yup.ref('ptacMax'), t('common.dryWeightLowerThanPTAC'))
      .transform((curr, orig) => (orig === '' ? null : curr)),
  });

  const {
    setValue,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    if (props.vehicleData) {
      // on initialise SaveCustomer pour ne pas perdre les données non-gérées lors de la sauvegarde
      setSaveVehicle({
        ...props.vehicleData,
      });

      setIsActive(props.vehicleData?.isActive ?? true);
      if (props.vehicleData?.carrier) {
        setCarrierOption({
          value: props.vehicleData?.carrier.id ?? '',
          label: props.vehicleData?.carrier.label ?? '',
          data: props.vehicleData?.carrier,
        });
        setValue('carrier', {
          value: props.vehicleData?.carrier.id ?? '',
          label: props.vehicleData?.carrier.label ?? '',
        });
      }

      setRegistrationPlate(props.vehicleData?.registrationPlate);
      setValue('registrationPlate', props.vehicleData?.registrationPlate);
      setTrailerPlate(props.vehicleData?.trailerPlate);
      setValue('trailerPlate', props.vehicleData?.trailerPlate);

      setTareWeight(props.vehicleData?.tareWeight ? props.vehicleData?.tareWeight.toString() : '');
      setValue('tareWeight', props.vehicleData?.tareWeight);
      setLastTareDate(props.vehicleData?.lastTareDate);
      setDsdTare(props.vehicleData?.dsdTare);
    }

    if (props.vehicleInfoData) {
      setSaveVehicleInfo({
        ...props.vehicleInfoData,
      });
      if (props.vehicleInfoData?.vehicleType) {
        setVehicleTypeOption({
          value: props.vehicleInfoData?.vehicleType.id ?? '',
          label: props.vehicleInfoData?.vehicleType.label ?? '',
          data: props.vehicleInfoData?.vehicleType,
        });
        setValue('vehicleType', {
          value: props.vehicleInfoData?.vehicleType.id ?? '',
          label: props.vehicleInfoData?.vehicleType.label ?? '',
        });
      }

      setPtacMax(props.vehicleInfoData?.ptacMax);
      setValue('ptacMax', props.vehicleInfoData?.ptacMax);
      setDryWeight(props.vehicleInfoData?.dryWeight);
      setValue('dryWeight', props.vehicleInfoData?.dryWeight);

      setMaxWeight(props.vehicleInfoData?.vehicleType?.maxWeight);
      setValue('maxWeight', props.vehicleInfoData?.vehicleType?.maxWeight);
      setSelectedOptionAxleNumber({
        value: (props.vehicleInfoData?.axleNumber ?? 0).toString(),
        label: (props.vehicleInfoData?.axleNumber ?? 0).toString(),
      });
      setValue('axleNumber', props.vehicleInfoData?.axleNumber);
      setSixthAxleAdditionalWeight(props.vehicleInfoData?.sixthAxleAditionalWeight);
      setValue('sixthAxleAdditionalWeight', props.vehicleInfoData?.sixthAxleAditionalWeight);

      setHasInductionBreak(props.vehicleInfoData?.hasInductionBreak ?? false);
      setValue('hasInductionBreak', props.vehicleInfoData?.hasInductionBreak);
      setInductionBreakAdditionalWeight(props.vehicleInfoData?.inductionBreakAdditionalWeight);
      setValue('inductionBreakAdditionalWeight', props.vehicleInfoData?.inductionBreakAdditionalWeight);
      setHasAlternativeMotorSystem(props.vehicleInfoData?.hasAlternativeMotorSystem ?? false);
      setValue('hasAlternativeMotorSystem', props.vehicleInfoData?.hasAlternativeMotorSystem);
      setAlternativeMotorSystemAdditionalWeight(props.vehicleInfoData?.alternativeMotorSystemAdditionalWeight);
      setValue('alternativeMotorSystemAdditionalWeight', props.vehicleInfoData?.alternativeMotorSystemAdditionalWeight);
    }
  }, [props.vehicleData, props.vehicleInfoData]);

  useEffect(() => {
    // set SelectedOptionAxleNumber to first value to prevent empty value by mistake
    if (optionsAxleNumber?.length > 0)
      setSelectedOptionAxleNumber({
        value: optionsAxleNumber[0].toString(),
        label: optionsAxleNumber[0].toString(),
      });
  }, [optionsAxleNumber]);

  function onSubmit() {
    setIsAddingLoading(true);
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');
    let baseUrl = orgid + '/vehicle/add';
    if (props.mode == 'update') baseUrl = orgid + '/vehicle/edit/' + props.vehicleData?.id;

    const dataToSend: VehicleSave = saveVehicle ?? {};

    dataToSend.registrationPlate = registrationPlate;
    dataToSend.trailerPlate = trailerPlate;
    dataToSend.tareWeight = parseFloat(tareWeight ?? '0');
    dataToSend.lastTareDate = lastTareDate;
    dataToSend.dsdTare = dsdTare;
    dataToSend.carrierId = saveVehicle?.carrierId;
    dataToSend.isActive = isActive;

    axios
      .post(Config.getApiExtranetUrl(baseUrl), stringUtils.formatFieldsForPost(dataToSend), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsAddingLoading(false);

        let baseUrl2 = orgid + '/vehicle-info/add';
        if (props.mode == 'update') baseUrl2 = orgid + '/vehicle-info/edit/' + props.vehicleInfoData?.id;

        if (saveVehicleInfo) {
          const dataToSend2: VehicleInfoSave = saveVehicleInfo;

          dataToSend2.registrationPlate = registrationPlate;
          dataToSend2.dryWeight = dryWeight;
          dataToSend2.ptacMax = ptacMax;
          dataToSend2.hasInductionBreak = hasInductionBreak;
          dataToSend2.inductionBreakAdditionalWeight = inductionBreakAdditionalWeight;
          dataToSend2.hasAlternativeMotorSystem = hasAlternativeMotorSystem;
          dataToSend2.alternativeMotorSystemAdditionalWeight = alternativeMotorSystemAdditionalWeight;
          if (dataToSend2.axleNumber === 6) dataToSend2.sixthAxleAditionalWeight = sixthAxleAdditionalWeight;
          else dataToSend2.sixthAxleAditionalWeight = undefined;
          dataToSend2.vehicleTypeId = saveVehicleInfo?.vehicleTypeId;

          axios
            .post(Config.getApiExtranetUrl(baseUrl2), stringUtils.formatFieldsForPost(dataToSend2), {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res2) => {
              setIsAddingLoading(false);

              addToast(t('common.vehicle' + (props.mode === 'update' ? 'Updated' : 'Created')), ToastTypes.success);
              props.onConfirm(res.data.content.uuid);
            })
            .catch((error) => {
              setIsAddingLoading(false);
              if (error.response) {
                if (
                  error.response.data.code == 'ERR4010001' ||
                  error.response.data.code == 'ERR4031001' ||
                  error.response.data.code == 'ERR4010000'
                ) {
                  history('/');
                }
              }
              addToast(
                (error?.response?.data?.code
                  ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
                  : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
                ToastTypes.error,
              );

              if (error.response.data.code == 'ERR4030012') {
                // Si le vehicle-info existe déjà, on continue
                props.onConfirm(res.data.content.uuid);
              }
            });
        }
      })
      .catch((error) => {
        setIsAddingLoading(false);
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  }

  const setAxleNumberOptions = (cont: any[]) => {
    const optionsAxleNumber: any = [];
    cont.map((value) => {
      let intValue: string | number = value;
      if (typeof value !== 'number') {
        intValue = parseInt(value);
      }
      optionsAxleNumber.push({
        value: intValue,
        label: intValue,
      });
    });

    setOptionsAxleNumber(optionsAxleNumber);
    if (!optionsAxleNumber.find((x: any) => x.value === (saveVehicleInfo?.axleNumber ?? 0)))
      setSelectedOptionAxleNumber(optionsAxleNumber[0]);
  };

  const calculatePTACMAX = () => {
    setValue(
      'maxOfPTAC',
      (maxWeight ?? 0) +
        (inductionBreakAdditionalWeight ?? 0) +
        (alternativeMotorSystemAdditionalWeight ?? 0) +
        ((saveVehicleInfo?.axleNumber ?? 0).toString() === '6' ? sixthAxleAdditionalWeight ?? 0 : 0) +
        0.0001,
    );

    return (
      (maxWeight ?? 0) +
      (inductionBreakAdditionalWeight ?? 0) +
      (alternativeMotorSystemAdditionalWeight ?? 0) +
      ((saveVehicleInfo?.axleNumber ?? 0).toString() === '6' ? sixthAxleAdditionalWeight ?? 0 : 0)
    );
  };

  return !props.vehicleData && props.mode === 'update' ? (
    <div>Loading...</div>
  ) : (
    <form id='form' onSubmit={handleSubmit(onSubmit)}>
      <CardDetail>
        <div className='form-section'>
          <FormRow>
            <Title label={t('common.generalInformations')} type='title1' />

            {props.mode === 'update' && (
              <FormRow align='right' alignVert='center'>
                <h3 className='base2'>{t('common.active')}</h3>
                <Switch
                  onChange={function () {
                    setIsActive((prevValue) => !prevValue);
                  }}
                  checked={isActive ?? false}
                  onColor={'#2a85ff'}
                />
              </FormRow>
            )}
          </FormRow>
          <FormRow>
            <Input
              {...register('registrationPlate')}
              error={errors.registrationPlate?.message ?? ''}
              label={t('common.registrationPlate')}
              type='text'
              value={registrationPlate ?? ''}
              placeholder=''
              disabled={props.mode === 'update'}
              onChange={function (e): void {
                setValue('registrationPlate', e.toString());
                setRegistrationPlate(e.toString());
              }}
            />
            <Input
              {...register('trailerPlate')}
              error={errors.trailerPlate?.message ?? ''}
              label={t('common.trailerPlate')}
              type='text'
              value={trailerPlate ?? ''}
              placeholder=''
              disabled={false}
              onChange={function (e): void {
                setValue('trailerPlate', e.toString());
                setTrailerPlate(e.toString());
              }}
            />
            <SelectCarrier
              titleSize='normal'
              register={register}
              registerName='carrier'
              setValue={setValue}
              error={errors.carrier?.value?.message ?? errors.carrier?.label?.message ?? ''}
              isSelectable={true}
              selectedOptionChanged={(e: SelectCarrierOption) => {
                if (e && e.value) {
                  setValue('carrier', {
                    value: e?.value,
                    label: e?.label,
                  });

                  setSaveVehicle((prevValue) => {
                    return { ...prevValue, carrierId: e?.value };
                  });
                } else {
                  setValue('carrier', {
                    value: '',
                    label: '',
                  });

                  setSaveVehicle((prevValue) => {
                    return { ...prevValue, carrierId: undefined };
                  });
                }
              }}
              forceSelectedOption={carrierOption}
            />
          </FormRow>
          <FormRow>
            <SelectVehicleType
              titleSize='normal'
              register={register}
              registerName='vehicleType'
              setValue={setValue}
              error={errors.vehicleType?.value?.message ?? errors.vehicleType?.label?.message ?? ''}
              isSelectable={true}
              selectedOptionChanged={(e: SelectVehicleTypeOption) => {
                if (e && e.value) {
                  setValue('vehicleType', {
                    value: e?.value,
                    label: e?.label,
                  });

                  if (e?.data?.axles === null || e?.data?.axles === undefined) {
                    setAxleNumberOptions([2, 3, 4, 5, 6]);
                  } else {
                    setAxleNumberOptions(e.data.axles.split(';'));
                  }

                  if (e?.data?.maxWeight !== null && e?.data?.maxWeight !== undefined) {
                    setMaxWeight(e.data.maxWeight);
                    setValue('maxWeight', e.data.maxWeight);
                  } else {
                    setMaxWeight(undefined);
                    setValue('maxWeight', undefined);
                  }

                  if (e?.data?.defaultDryWeight !== null && e?.data?.defaultDryWeight) {
                    setDryWeight(e.data.defaultDryWeight);
                    setValue('dryWeight', e.data.defaultDryWeight);
                  }

                  setSaveVehicleInfo((prevValue) => {
                    return { ...prevValue, vehicleTypeId: e?.value };
                  });
                } else {
                  setValue('vehicleType', {
                    value: '',
                    label: '',
                  });

                  setSaveVehicleInfo((prevValue) => {
                    return { ...prevValue, vehicleTypeId: undefined };
                  });
                }

                setOptionsAxleNumber(e?.data?.axles?.split(';') ?? vehicleTypeOption?.data?.axles?.split(';') ?? []);
              }}
              forceSelectedOption={vehicleTypeOption}
            />
            <Input
              {...register('ptacMax')}
              error={errors.ptacMax?.message ?? ''}
              label={
                t('common.ptac') + (calculatePTACMAX ? ' ( max. ' + Number(calculatePTACMAX().toFixed(2)) + ' T )' : '')
              }
              type='number'
              number={{ min: 0, max: calculatePTACMAX() ?? 0 + 0.0000001, step: 0.01 }}
              prefix='T'
              value={ptacMax ?? ''}
              placeholder='0'
              disabled={false}
              onChange={function (e): void {
                if (e.toString() !== '') {
                  setValue('ptacMax', parseFloat(e?.toString() ?? '0'));
                  setPtacMax(parseFloat(e.toString() ?? '0'));
                } else {
                  setValue('ptacMax', undefined);
                  setPtacMax(undefined);
                }
              }}
            />
            <Input
              {...register('dryWeight')}
              error={errors.dryWeight?.message ?? ''}
              label={t('common.dryWeight')}
              type='number'
              number={{ min: 0, max: maxWeight ?? null, step: 0.01 }}
              prefix='T'
              value={dryWeight ?? ''}
              placeholder=''
              disabled={false}
              onChange={function (e): void {
                if (e.toString() !== '') {
                  setDryWeight(parseFloat(e.toString() ?? '0'));
                } else {
                  setDryWeight(undefined);
                }
              }}
            />
          </FormRow>
          <FormRow>
            <Input
              {...register('tareWeight')}
              error={errors.tareWeight?.message ?? ''}
              label={t('common.lastTare')}
              hoverDescription={t('common.lastTareDesc')}
              type='number'
              number={{ min: 0, max: maxWeight ?? null, step: 0.01 }}
              prefix='T'
              value={tareWeight ?? ''}
              placeholder='0'
              disabled={false}
              onChange={function (e): void {
                if (e.toString() !== '') {
                  setValue('tareWeight', parseFloat(e?.toString() ?? '0'));
                  setTareWeight(e.toString());
                  setLastTareDate(undefined);
                  setDsdTare(undefined);
                } else {
                  setValue('tareWeight', undefined);
                  setTareWeight(undefined);
                  setLastTareDate(undefined);
                  setDsdTare(undefined);
                }
              }}
            />
            <Input
              error={''}
              label={t('common.lastTareDate')}
              hoverDescription={t('common.lastTareDateDesc')}
              type={lastTareDate ? 'datetime-local' : 'text'}
              value={lastTareDate ? moment(lastTareDate).tz(siteTimeZone).format('YYYY-MM-DDTHH:mm') : ''}
              placeholder=''
              disabled={true}
              onChange={(newValue: string | number) => {
                return null;
              }}
            />
            <Input
              error={''}
              label={t('common.lastTareDSD')}
              hoverDescription={t('common.lastTareDSDDesc')}
              type={'text'}
              value={dsdTare ?? ''}
              placeholder=''
              disabled={true}
              onChange={(newValue: string | number) => {
                return null;
              }}
            />
          </FormRow>
        </div>

        <div className='form-section'>
          <FormRow>
            <Title label={t('common.maxWeightCalculation')} type='title1' />
          </FormRow>
          <FormRow>
            <Input
              {...register('maxWeight')}
              error={errors.maxWeight?.message ?? ''}
              label={t('common.theoreticalMaxWeight')}
              type='number'
              prefix='T'
              value={maxWeight ?? ''}
              placeholder=''
              disabled={true}
              onChange={function (e): void {
                setMaxWeight(parseFloat(e.toString() ?? '0'));
              }}
            />
            <SelectAxleNumber
              titleSize='normal'
              titleOverride={t('common.axleNumber')}
              register={register}
              registerName='axleNumber'
              setValue={setValue}
              error={errors.axleNumber?.message ?? ''}
              isSelectable={true}
              selectedOptionChanged={(e: any) => {
                if (e && e.value) {
                  setValue('axleNumber', e?.value);

                  setSaveVehicleInfo((prevValue) => {
                    return { ...prevValue, axleNumber: parseFloat(e?.value as string) };
                  });
                } else {
                  setValue('axleNumber', 0);

                  setSaveVehicleInfo((prevValue) => {
                    return { ...prevValue, axleNumber: undefined };
                  });
                }
              }}
              forceSelectedOption={selectedOptionAxleNumber}
              searchParent={optionsAxleNumber}
              dependsOnSearchParent={true}
            />

            {(saveVehicleInfo?.axleNumber ?? 0).toString() === '6' && (
              <Input
                {...register('sixthAxleAdditionalWeight')}
                error={errors.sixthAxleAdditionalWeight?.message ?? ''}
                label={t('common.sixthAxleAdditionalWeight') + ' ( max. +1T )'}
                type='number'
                number={{ min: 0, max: 1, step: 0.01 }}
                prefix='T'
                value={sixthAxleAdditionalWeight ?? ''}
                placeholder='0'
                disabled={false}
                onChange={function (e): void {
                  setValue('sixthAxleAdditionalWeight', parseFloat(e?.toString() ?? '0'));
                  setSixthAxleAdditionalWeight(parseFloat(e.toString() ?? '0'));
                }}
              />
            )}
          </FormRow>
          <FormRow>
            <div className='dune-content-input dune-content-input-wide'>
              <div className='input__title'>
                <input
                  type='checkbox'
                  {...register('hasAlternativeMotorSystem', {
                    // value: { hasAlternativeMotorSystem },
                  })}
                  checked={hasAlternativeMotorSystem === true ?? false}
                  onChange={(e) => setHasAlternativeMotorSystem(e?.target.checked)}
                  // disabled={isDisabled}
                />
                {t('common.alternativeMotor')}
                <i>{' ( max. +1T )'}</i>
              </div>

              {hasAlternativeMotorSystem && (
                <Input
                  {...register('alternativeMotorSystemAdditionalWeight')}
                  error={errors.alternativeMotorSystemAdditionalWeight?.message ?? ''}
                  type='number'
                  number={{ min: 0, max: 1, step: 0.01 }}
                  prefix='T'
                  value={alternativeMotorSystemAdditionalWeight ?? ''}
                  placeholder='0'
                  disabled={false}
                  onChange={function (e): void {
                    setValue('alternativeMotorSystemAdditionalWeight', parseFloat(e?.toString() ?? '0'));
                    setAlternativeMotorSystemAdditionalWeight(parseFloat(e.toString() ?? '0'));
                  }}
                />
              )}
            </div>

            <div className='dune-content-input dune-content-input-wide'>
              <div className='input__title'>
                <input
                  type='checkbox'
                  {...register('hasInductionBreak', {
                    // value: { hasInductionBreak },
                  })}
                  checked={hasInductionBreak === true ?? false}
                  onChange={(e) => setHasInductionBreak(e?.target.checked)}
                  // disabled={isDisabled}
                />
                {t('common.inductionBreak')}
                <i>{' ( max. +0.5T )'}</i>
              </div>

              {hasInductionBreak && (
                <Input
                  {...register('inductionBreakAdditionalWeight')}
                  error={errors.inductionBreakAdditionalWeight?.message ?? ''}
                  type='number'
                  number={{ min: 0, max: 0.5, step: 0.01 }}
                  prefix='T'
                  value={inductionBreakAdditionalWeight ?? ''}
                  placeholder='0'
                  disabled={false}
                  onChange={function (e): void {
                    setValue('inductionBreakAdditionalWeight', parseFloat(e?.toString() ?? '0'));
                    setInductionBreakAdditionalWeight(parseFloat(e.toString() ?? '0'));
                  }}
                />
              )}
            </div>
          </FormRow>
        </div>
        <FixBar>
          <Button label={t('common.return')} style='white' onClick={props.onCancel} />
          <Button iconLeft='save' label={t('common.save')} style='primary' onClick={handleSubmit(onSubmit)} />
        </FixBar>
      </CardDetail>
    </form>
  );
});

GeneralVehicle.displayName = 'GeneralVehicle';
export default GeneralVehicle;
