import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Config from '../Config';
import {
  BarrierDevice,
  Curtain,
  LapiCamera,
  Printer,
  SkipCamera,
  TerminalSensor,
  TrafficLight,
  Weighbridge,
} from './ControllableDevices';
import Menu from './Menu';
import { t } from 'i18next';
import Header from './Header';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import eventBus from '../utils/EventBus';

const ManageEquipments = React.memo(() => {
  const [terminals, setTerminals] = useState<any[]>([]);
  const [deviceGroups, setDeviceGroups] = useState([]);
  const [equipmentGroups, setEquipmentGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState(0);
  let lastFetch = new Date();

  const fetchEquipments = () => {
    setIsLoading(true);
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');
    const url = `${orgid}/terminal/get-equipment`;

    axios
      .get(Config.getApiExtranetUrl(url), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (res) => {
        const terms = res.data.content;
        if (res.data.content) {
          // setEquipments(res.data.content[0].terminalEquipments);
          // for each terminal, group the equipments by type
          for (const terI in terms) {
            const ter = terms[terI];
            const terGroups: any = {};
            // ter.terminalEquipments.sort((a: any, b: any) => {
            //   return a.id - b.id;
            // });
            for (const i in ter.terminalEquipments) {
              const equipment = ter.terminalEquipments[i];
              if (equipment.lastReceivedData && typeof equipment.lastReceivedData === 'string') {
                equipment.lastReceivedData = JSON.parse(equipment.lastReceivedData);
              }
              if (equipment.lastSentCommand && typeof equipment.lastSentCommand === 'string') {
                equipment.lastSentCommand = JSON.parse(equipment.lastSentCommand);
              }
              const eqParent: string = equipment.equipmentType.code.split('-')[0]; // SINGLE, BARRIER, ANPRCAMERA, etc.
              if (eqParent !== 'SINGLE') {
                if (terGroups[eqParent] === undefined) terGroups[eqParent] = {};
                // fill groups with equipments as such : { BARRIER: { 'PRESENCESENSOR': { ... }, 'STATUSSENSOR': { ... } } }
                terGroups[eqParent][equipment.equipmentType.code.split('-')[1]] = equipment;
              } else {
                if (terGroups[equipment.equipmentType.code] === undefined) terGroups[equipment.equipmentType.code] = {};
                terGroups[equipment.equipmentType.code][equipment.equipmentType.code.split('-')[1]] = equipment;
              }
            }
            // terGroups.sort();
            const final = {};
            const keys = Object.keys(terGroups);
            // sort the groups by generic type to display 'device' first
            keys.sort((a, b) => {
              if (a === 'SINGLE') {
                return 1;
              } else {
                const aGroupFirstObj = Object.keys(terGroups[a])[0];
                const bGroupFirstObj = Object.keys(terGroups[b])[0];
                const genTypeA = terGroups[a][aGroupFirstObj].equipmentType.genericType === 'device' ? 0 : 1;
                const genTypeB = terGroups[b][bGroupFirstObj].equipmentType.genericType === 'device' ? 0 : 1;
                if (genTypeA != genTypeB) {
                  return genTypeA - genTypeB;
                } else {
                  return terGroups[a][aGroupFirstObj].equipementGroup - terGroups[b][bGroupFirstObj].equipementGroup;
                }
              }
            });
            for (let i = 0; i < keys.length; i++) {
              const key = keys[i];
              // @ts-expect-error: description to add.
              final[key] = terGroups[key];
            }
            terms[terI].grouped = final;
          }
          setTerminals(terms);
        }
        setIsLoading(false);
      });
  };
  useEffect(() => {
    fetchEquipments();
    const handleTerminalHasBeenUpdated = (payload: any) => {
      const message = payload.detail.message;
      const splits = message.split('¤');
      const [equipmentId, terminalId, data] = [splits[1], splits[3], splits[5]];
      setTerminals((previous) => {
        const terms = previous.map((terminal) => {
          if (terminal.id === terminalId) {
            const updatedEquipments = terminal.terminalEquipments.map((equipment: any) => {
              if (equipment.id === equipmentId) {
                return { ...equipment, lastReceivedData: JSON.parse(data) };
              }
              return equipment;
            });
            return { ...terminal, terminalEquipments: updatedEquipments };
          }
          return terminal;
        });
        for (const terI in terms) {
          const ter = terms[terI];
          const terGroups: any = {};
          for (const i in ter.terminalEquipments) {
            const equipment = ter.terminalEquipments[i];
            const group: string = equipment.equipmentType.code.split('-')[0];
            if (group !== 'SINGLE') {
              if (terGroups[group] === undefined) terGroups[group] = {};
              terGroups[group][equipment.equipmentType.code.split('-')[1]] = equipment;
            } else {
              if (terGroups[equipment.equipmentType.code] === undefined) terGroups[equipment.equipmentType.code] = {};
              terGroups[equipment.equipmentType.code][equipment.equipmentType.code.split('-')[1]] = equipment;
            }
          }
          const final = {};
          const keys = Object.keys(terGroups);
          keys.sort((a, b) => {
            if (a === 'SINGLE') {
              return 1;
            } else {
              const aGroupFirstObj = Object.keys(terGroups[a])[0];
              const bGroupFirstObj = Object.keys(terGroups[b])[0];
              const genTypeA = terGroups[a][aGroupFirstObj].equipmentType.genericType === 'device' ? 0 : 1;
              const genTypeB = terGroups[b][bGroupFirstObj].equipmentType.genericType === 'device' ? 0 : 1;
              if (genTypeA != genTypeB) {
                return genTypeA - genTypeB;
              } else {
                return terGroups[a][aGroupFirstObj].equipementGroup - terGroups[b][bGroupFirstObj].equipementGroup;
              }
            }
          });
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            // @ts-expect-error: description to add.
            final[key] = terGroups[key];
          }
          terms[terI].grouped = final;
        }
        setTerminals(terms);
        return terms;
      });
    };
    const handleTerminalHasBeenUpdatedCallback = (payload: any) => {
      if (new Date().getTime() - lastFetch.getTime() > 3000) {
        lastFetch = new Date();
        fetchEquipments();
      }
    };
    eventBus.on('terminal_has_been_updated', handleTerminalHasBeenUpdated);
    eventBus.on('terminal_has_been_updated_callback', handleTerminalHasBeenUpdatedCallback);

    return () => {
      // cleanup events on unmount
      eventBus.remove('terminal_has_been_updated', handleTerminalHasBeenUpdated);
      eventBus.remove('terminal_has_been_updated_callback', handleTerminalHasBeenUpdatedCallback);
    };
  }, []);

  return (
    <div className={'equipments main-container'}>
      <Menu selectedPage='manageequipments'></Menu>
      <div className={'right table-container'}>
        <Header selectedPage={t('menu.equipments')}></Header>
        <Tabs defaultIndex={1} onSelect={(index) => setCurrentTab(index)} className={'transparent-tabs'}>
          <div style={{ flexDirection: 'row', display: 'flex' }}>
            <TabList>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  borderSpacing: '10px',
                  width: '300px',
                  justifyContent: 'flex-start',
                  gap: '2px',
                }}
              >
                {terminals.map((terminal: any, index: number) => {
                  return (
                    <Tab key={index} className={currentTab === index ? 'tab_active' : 'tab'}>
                      <div className='base2'>{terminal.label.split('/')[0]}</div>
                    </Tab>
                  );
                })}
              </div>
            </TabList>
          </div>
          {terminals.map((terminal: any, index: number) => {
            return (
              <TabPanel key={index}>
                <div
                  style={{
                    height: '100%',
                    width: '100%',
                    overflow: 'auto',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'flex-start',
                  }}
                >
                  {Object.entries(terminal.grouped).map((entry: any) => {
                    const group = entry[0];
                    const equipments = entry[1];

                    switch (group) {
                      case 'TERMINAL':
                        return <TerminalSensor data={equipments['PRESENCESENSOR']}></TerminalSensor>;
                      case 'SINGLE-PRESENCESENSOR':
                        return <TerminalSensor data={equipments['PRESENCESENSOR']}></TerminalSensor>;
                      case 'ANPRCAMERA':
                        return (
                          <LapiCamera
                            data={equipments}
                            terminalId={terminal.id}
                            onReload={() => {
                              fetchEquipments();
                            }}
                          ></LapiCamera>
                        );
                      case 'SINGLE-TRUCKBEDCAMERA':
                        return (
                          <SkipCamera
                            data={equipments}
                            terminalId={terminal.id}
                            onReload={() => {
                              fetchEquipments();
                            }}
                          ></SkipCamera>
                        );
                      case 'SHUTTER':
                        return <Curtain data={equipments} terminalId={terminal.id}></Curtain>;
                      case 'PRINTER':
                        return <Printer data={equipments} terminalId={terminal.id}></Printer>;
                      case 'BARRIER':
                        return <BarrierDevice data={equipments} terminalId={terminal.id}></BarrierDevice>;
                      case 'EXTRAPOLATED':
                        return <Curtain data={equipments}></Curtain>;
                      case 'TRAFFICLIGHT':
                        return <TrafficLight data={equipments} terminalId={terminal.id}></TrafficLight>;
                      case 'WEIGHBRIDGE':
                        return <Weighbridge data={equipments} terminalId={terminal.id}></Weighbridge>;
                      default:
                        return <div />;
                    }
                  })}
                </div>
              </TabPanel>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
});

ManageEquipments.displayName = 'ManageEquipments';
export default ManageEquipments;
