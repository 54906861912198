import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastTypes } from '../../../models/ToastTypes';
import useToast from '../../../hooks/use-toast';
import Config from '../../../Config';
import FilterHeader, { AdvancedFilter } from '../FilterHeader';
import TabFilter from '../TabFilter';
import { WasteRequestStatus, GetWasteRequest, GetWasteRequestParameters } from '../../../models/WasteRequest';
import DataTable from 'react-data-table-component';
import TableStyles from '../../../styles/tableStyles';
import moment from 'moment';
import { SelectStatus } from '../../forms/SelectStatus';
import { SelectBuildingSite, SelectBuildingSiteOption } from '../../forms/SelectBuildingSite';
import { Button } from '../../../stories/dune/atoms/Button';
import { FormRow } from '../../../stories/dune/atoms/FormRow';
import { GlobalParameter } from '../../../models/GlobalParameter';

interface ListWasteRequestsProps {
  defaultStatus?: string;
  forcedFilters?: AdvancedFilter[];
}

const ListWasteRequests = React.memo((props: ListWasteRequestsProps) => {
  // #region const init

  // #region generic
  const { t } = useTranslation();
  const { addToast } = useToast();

  const [isLoading, setIsLoading] = useState(true);

  const history = useNavigate();

  const [filtersAdvanced, setFiltersAdvanced] = useState<AdvancedFilter[]>(props.forcedFilters ?? []);
  const [filtersTab, setFiltersTab] = useState<string[]>(props.defaultStatus ? [props.defaultStatus] : []);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState(20);
  const [sortColumn, setSortColumn] = useState('createdAt');
  const [sortDirection, setSortDirection] = useState('desc');
  const [totalRows, setTotalRows] = useState(0);
  const [totalResource, setTotalResource] = useState(0);

  const [siteTimeZone, setSiteTimeZone] = useState<string>('Europe/Paris');
  useEffect(() => {
    const globalParametersSite: GlobalParameter[] = JSON.parse(localStorage.getItem('globalParameters') ?? '[]');
    setSiteTimeZone(globalParametersSite.find((x) => x.label === 'siteTimeZone')?.value ?? 'Europe/Paris');
  }, []);

  // #endregion generic

  // #region specific

  const [wasteRequestHost, setWasteRequestHost] = useState<string>('');

  const [filteredItems, setFilteredItems] = useState<GetWasteRequest[]>([]);

  useEffect(() => {
    const host = JSON.parse(localStorage.getItem('wasteRequestHost') ?? '{"host": ""}');
    setWasteRequestHost(host.host);
  }, []);

  const fetchItems = (
    page: number,
    newPerPage: number,
    newSortColumn: string = sortColumn,
    newSortDirection: string = sortDirection,
    TabFilter: string[] = filtersTab,
    AdvancedFilters: AdvancedFilter[] = filtersAdvanced,
  ) => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');

    const url =
      orgid +
      '/waste-request/get?page=' +
      page +
      '&limit=' +
      newPerPage +
      '&sortBy=' +
      newSortColumn +
      '&orderBy=' +
      newSortDirection;

    setCurrentPage(page);
    setIsLoading(true);
    setFilteredItems([]);

    if (newSortColumn != sortColumn) {
      setSortColumn(newSortColumn);
    }

    if (newSortDirection != sortDirection) {
      setSortDirection(newSortDirection);
    }

    // setTotalRows(fakeData.meta.total);
    // setTotalResource(fakeData.meta.total);
    // setFilteredItems(fakeData.content);

    // setIsLoading(false);
    // return;

    axios
      .post(
        Config.getApiExtranetUrl(url),
        { ...getFiltersToSend(TabFilter, AdvancedFilters) },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(async (res) => {
        setTotalRows(res.data.meta.total);
        setTotalResource(res.data.meta.total);
        setFilteredItems(res.data.content.items);

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  };

  function getFiltersToSend(tabFilters: string[], advancedFilters: AdvancedFilter[]) {
    const toSend: GetWasteRequestParameters = {};

    if (tabFilters?.length > 0) toSend.status = tabFilters;

    if (advancedFilters.length > 0) {
      advancedFilters.map((x) => {
        if (x.filterType === 'number') toSend.wasteRequestNumber = x.filterData;
        if (x.filterType === 'buildingSite') toSend.buildingSiteId = x.filterData?.value;
        if (x.filterType === 'customer') toSend.customerId = x.filterData?.value;
        if (x.filterType === 'externalReference') toSend.externalReference = x.filterData;
      });
    }

    return toSend;
  }

  const tabFilterOptions = [
    { tabLabel: 'Tous', tabLabelTranslate: t('common.all') },
    {
      tabLabel: WasteRequestStatus.TO_BE_COMPLETED,
      tabLabelTranslate: t('status.' + WasteRequestStatus.TO_BE_COMPLETED),
    },
    {
      tabLabel: WasteRequestStatus.REQUESTED,
      tabLabelTranslate: t('status.' + WasteRequestStatus.REQUESTED),
    },
    { tabLabel: WasteRequestStatus.ACCEPTED, tabLabelTranslate: t('status.' + WasteRequestStatus.ACCEPTED) },
    { tabLabel: WasteRequestStatus.REFUSED, tabLabelTranslate: t('status.' + WasteRequestStatus.REFUSED) },
  ];

  const seeDetails = (row: GetWasteRequest) => {
    return row.detailUrl;
  };

  const columns: any[] = [
    {
      name: t('common.number'),
      selector: (row: GetWasteRequest) => row.wasteRequestNumber,
      cell: (row: GetWasteRequest) => (
        <div className='column' data-tag='allowRowEvents'>
          <div data-tag='allowRowEvents'>
            <strong className='caption2medium' data-tag='allowRowEvents'>
              {row.wasteRequestNumber}
            </strong>
          </div>
        </div>
      ),
      sortable: true,
      right: false,
      id: 'wasteRequestNumber',
      grow: 0.7,
    },
    {
      name: t('common.status'),
      selector: (row: GetWasteRequest) => row.status,
      cell: (row: GetWasteRequest) => (
        <div className='column' data-tag='allowRowEvents'>
          <SelectStatus
            registerName={''}
            error={''}
            titleSize='none'
            forceSelectedOption={row.status}
            statusType={'wasteRequest'}
            isSelectable={false}
            data-tag='allowRowEvents'
          />
        </div>
      ),

      sortable: true,
      right: false,
      id: 'status',
      grow: 0.7,
    },
    {
      name: t('common.signatures'),
      selector: (row: GetWasteRequest) => row.siteIsSigned,
      cell: (row: GetWasteRequest) => (
        <div className='column column-align-left' data-tag='allowRowEvents'>
          <div data-tag='allowRowEvents'>
            <div className='caption2medium' data-tag='allowRowEvents'>
              {countSignatures(row) + ' / 4'}
            </div>
          </div>
        </div>
      ),
      sortable: false,
      right: false,
      id: 'signatures',
      grow: 0.5,
    },
    {
      name: t('common.creationDate'),
      selector: (row: GetWasteRequest) => row.createdAt,
      cell: (row: GetWasteRequest) => (
        <div className='column' data-tag='allowRowEvents'>
          <div data-tag='allowRowEvents'>
            <div className='caption2medium' data-tag='allowRowEvents'>
              {moment(row.createdAt).tz(siteTimeZone).format('DD/MM/YYYY, HH:mm')}
            </div>
          </div>
        </div>
      ),
      sortable: true,
      right: false,
      id: 'createdAt',
      grow: 0.7,
    },
    {
      name: 'Chantier de la DAP',
      selector: (row: GetWasteRequest) => row.dataCustom,
      cell: (row: GetWasteRequest) => (
        <>
          <div className='column column-align-left' data-tag='allowRowEvents'>
            <div data-tag='allowRowEvents'>
              <strong className='caption2medium' data-tag='allowRowEvents'>
                {row?.originExternalReference ?? ''}
              </strong>
            </div>
            <div data-tag='allowRowEvents'>
              <i className='caption2medium' data-tag='allowRowEvents'>
                {row?.originAddress ?? ''}
              </i>
            </div>
          </div>
        </>
      ),
      sortable: false,
      right: false,
      id: 'buildingSite',
    },
    {
      name: t('common.jobSite'),
      selector: (row: GetWasteRequest) => row.buildingSite?.id,
      cell: (row: GetWasteRequest) => (
        <>
          {row.buildingSite != null && (
            <div className='column' data-tag='allowRowEvents'>
              <div data-tag='allowRowEvents'>
                <strong className='caption2medium' data-tag='allowRowEvents'>
                  {row.buildingSite?.label}
                </strong>
              </div>
              <div data-tag='allowRowEvents'>
                <i className='caption2medium' data-tag='allowRowEvents'>
                  {row.buildingSite?.customer?.label}
                </i>
              </div>
            </div>
          )}

          {row.buildingSite == null && (
            <SelectBuildingSite
              setValue={undefined}
              register={undefined}
              registerName='buildingSite'
              titleOverride=''
              error={''}
              selectedOptionChanged={(selectedOption: SelectBuildingSiteOption) => {
                row.buildingSite = selectedOption.data;
                if (selectedOption.data?.customer) row.buildingSite.customer = selectedOption.data.customer;
              }}
              isPopup={false}
              onClosePopup={undefined}
              searchParent={''}
              dependsOnSearchParent={false}
              isSelectable={true}
              inRow={true}
              showParent={true}
              placeholder='Associer'
            />
          )}
        </>
      ),
      sortable: false,
      right: false,
      id: 'buildingSite',
    },
    {
      name: '',
      selector: (row: GetWasteRequest) => row.siteIsSigned,
      cell: (row: GetWasteRequest) => (
        <Button
          label={t('common.actions')}
          hoverDescription={t('common.wasteActionsDesc')}
          size='medium'
          style={'primary'}
          iconLeft={'expand'}
          dropdown={true}
          listDropdown={
            row.status !== WasteRequestStatus.REFUSED && row.status !== WasteRequestStatus.ACCEPTED
              ? row.status === WasteRequestStatus.REQUESTED
                ? [
                    <Button
                      key='download'
                      label={t('common.download')}
                      iconLeft='download'
                      style='white'
                      size='widethin'
                      onClick={() => handleDownload(row)}
                    />,
                    <Button
                      key='valid'
                      label={t('common.sign')}
                      iconLeft='valid'
                      style='white'
                      size='widethin'
                      onClick={() => handleSign(row)}
                    />,
                    <Button
                      key='refuse'
                      label={t('common.refuse')}
                      iconLeft='close'
                      style='white'
                      size='widethin'
                      onClick={() => handleRefuse(row)}
                    />,
                  ]
                : [
                    <Button
                      key='download'
                      label={t('common.download')}
                      iconLeft='download'
                      style='white'
                      size='widethin'
                      onClick={() => handleDownload(row)}
                    />,
                    <Button
                      key='refuse'
                      label={t('common.refuse')}
                      iconLeft='close'
                      style='white'
                      size='widethin'
                      onClick={() => handleRefuse(row)}
                    />,
                  ]
              : [
                  <Button
                    key='download'
                    label={t('common.download')}
                    iconLeft='download'
                    style='white'
                    size='widethin'
                    onClick={() => handleDownload(row)}
                  />,
                ]
          }
        />
      ),
      sortable: false,
      right: false,
      id: 'actions',
    },
  ];

  const setAcceptStatus = (row: GetWasteRequest, status: boolean) => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');

    axios
      .post(
        Config.getApiExtranetUrl(orgid + '/waste-request/' + row.id + '/accept'),
        { isAccepted: status },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(async (res) => {
        addToast(t('common.wasteRequestUpdateSuccess'), ToastTypes.success);
        fetchItems(currentPage, perPage, sortColumn, sortDirection, filtersTab, filtersAdvanced);
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  };

  const countSignatures = (row: GetWasteRequest) => {
    let count = 0;
    if (row.siteIsSigned) count++;
    if (row.producerIsSigned) count++;
    if (row.carrierIsSigned) count++;
    if (row.requesterIsSigned) count++;
    return count;
  };

  // #endregion specific

  // #endregion const init

  // #region event handling

  // useEffect(() => {
  //   fetchItems(1, perPage); // instead called by filtersHeader loading
  // }, []);

  const handleDownload = (row: GetWasteRequest) => {
    window.open(row.pdfUrl, '_blank');
  };

  const handleRefuse = (row: GetWasteRequest) => {
    setAcceptStatus(row, false);
  };

  const handleSign = (row: GetWasteRequest) => {
    setAcceptStatus(row, true);
  };

  const handlePageChange = (pageNumber: number) => {
    fetchItems(pageNumber, perPage);
  };

  const handlePerRowsChange = (newPerPage: any) => {
    setPerPage(newPerPage);
    fetchItems(1, newPerPage);
  };

  const onSort = (data: any, newSortOrder: any) => {
    if (data.id == sortColumn) {
      if (sortDirection == 'asc') {
        newSortOrder = 'desc';
      } else {
        newSortOrder = 'asc';
      }
    } else {
      newSortOrder = 'asc';
    }
    fetchItems(1, perPage, data.id, newSortOrder);
  };

  function handleSetFiltersAdvanced(newFilters: AdvancedFilter[]) {
    setFiltersAdvanced(newFilters);
    fetchItems(currentPage, perPage, sortColumn, sortDirection, filtersTab, newFilters);
  }

  function handleSetFiltersStatus(newFilters: string[]) {
    setFiltersTab(newFilters);
    fetchItems(currentPage, perPage, sortColumn, sortDirection, newFilters, filtersAdvanced);
  }

  // #endregion event handling

  return (
    <>
      <FilterHeader
        title={''}
        allowQuickSearch={true}
        allowAdvancedSearch={true}
        advancedSearchOptions={['number', 'externalReference', 'customer', 'buildingSite']}
        showAddNew={(wasteRequestHost ?? null) !== null && (wasteRequestHost ?? '') !== ''}
        addNewText={t('common.newWasteRequest')}
        addNewIcon={'openLink'}
        addNew={() => {
          window.open(wasteRequestHost, '_blank');
        }}
        addNewDesc={wasteRequestHost}
        forcedFilters={props.forcedFilters}
        quickSearchFilterTypes={['number']}
        advancedSearchFilterChanged={handleSetFiltersAdvanced}
      />
      <TabFilter
        tabArray={tabFilterOptions}
        parent={'WasteRequest'}
        filterSet={handleSetFiltersStatus}
        defaultTab={props.defaultStatus ?? 'Tous'}
      />
      {isLoading ?? false ? (
        <div>{t('common.loading')}</div>
      ) : (
        <DataTable
          className='table'
          noHeader={true}
          customStyles={TableStyles}
          columns={columns}
          onRowClicked={(row) => window.open(seeDetails(row), '_blank')}
          highlightOnHover
          pointerOnHover
          data={filteredItems}
          noDataComponent={<div className='noResults'>{t('common.noResult')}</div>}
          defaultSortAsc={sortDirection == 'asc'}
          defaultSortFieldId={sortColumn}
          onSort={(data, newSortDirection) => onSort(data, newSortDirection)}
          sortServer
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          paginationDefaultPage={currentPage}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationPerPage={perPage}
          paginationRowsPerPageOptions={[5, 10, 20, 50, 100, 200]}
          paginationComponentOptions={{
            rowsPerPageText: t('common.perPage'),
            rangeSeparatorText: t('common.onPage'),
            noRowsPerPage: false,
            selectAllRowsItem: false,
            selectAllRowsItemText: 'All',
          }}
        />
      )}
    </>
  );
});

ListWasteRequests.displayName = 'ListWasteRequests';
export default ListWasteRequests;
