import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CardDetail } from '../../../stories/dune/atoms/CardDetail';
import { FormRow } from '../../../stories/dune/atoms/FormRow';
import { Input } from '../../../stories/dune/atoms/Input';
import { ZoneSave, GetZone, ZoneType } from '../../../models/Zone';
import BuildDataCustom, { DataCustomItem } from '../../forms/BuildDataCustom';
import { yupResolver } from '@hookform/resolvers/yup';
import { UseFormRegisterReturn, Validate, ValidationRule, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { FixBar } from '../../../stories/dune/molecules/FixBar';
import { Button } from '../../../stories/dune/atoms/Button';
import useToast from '../../../hooks/use-toast';
import axios from 'axios';
import { ToastTypes } from '../../../models/ToastTypes';
import Config from '../../../Config';
import Switch from 'react-switch';
import stringUtils from '../../../utils/stringUtils';
import { SelectZoneType, SelectZoneTypeOption } from '../../forms/SelectZoneType';

interface GeneralZoneProps {
  id?: string;
  zoneData: GetZone | undefined;

  onCancel: () => void;
  onConfirm: (data: any) => void;

  mode: string;
}

interface IFormInputs {
  label: string;
  externalReference: string;
  zoneType: {
    value: string;
    label: string;
  };
  maxOccupation: number;
  currentOccupation: number;
}

const GeneralZone = React.memo((props: GeneralZoneProps) => {
  const { t } = useTranslation();
  const history = useNavigate();
  const { addToast, addFixedToast } = useToast();

  const [isAddingLoading, setIsAddingLoading] = useState(false);

  const [saveZone, setSaveZone] = useState<ZoneSave>();

  const [isActive, setIsActive] = useState<boolean>(true);

  const [label, setLabel] = useState('');
  const [externalReference, setExternalReference] = useState('');
  const [maxOccupation, setMaxOccupation] = useState<number | null>();
  const [currentOccupation, setCurrentOccupation] = useState<number>();
  const [selectedOptionZoneType, setSelectedOptionZoneType] = useState<SelectZoneTypeOption | null>(
    props.zoneData?.zoneType
      ? {
          value: props.zoneData.zoneType,
          label: t('zoneType.' + props.zoneData.zoneType),
          data: { value: props.zoneData.zoneType, label: t('zoneType.' + props.zoneData.zoneType) },
        }
      : null,
  );

  // const [zoneDataCustom, setZoneDataCutom] = useState<DataCustomItem>({});

  const schema = yup.object().shape({
    zoneType: yup.object().shape({
      value: yup.string().required(t('zone.requiredType')),
      label: yup.string().required(t('zone.requiredType')),
    }),
    label: yup.string().required(t('zone.requiredLabel')),
    maxOccupation: yup
      .number()
      .min(0, t('common.PositiveMaxOcc'))
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr)),
  });

  const {
    setValue,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    if (props.zoneData) {
      // on initialise SaveZone pour ne pas perdre les données non-gérées lors de la sauvegarde
      setSaveZone({
        ...props.zoneData,
        products: props.zoneData?.products?.map((p) => p.id ?? ''),
        childZonesId: props.zoneData?.childZones?.map((z) => z.id ?? ''),
      });

      setIsActive(props.zoneData?.isActive ?? true);

      setLabel(props.zoneData?.label ?? '');
      setValue('label', props.zoneData?.label ?? '');
      setExternalReference(props.zoneData?.externalReference ?? '');
      setCurrentOccupation(props.zoneData?.currentOccupation ?? 0);
      setMaxOccupation(props.zoneData?.maxOccupation);
      setIsActive(props.zoneData?.isActive ?? false);

      setSelectedOptionZoneType({
        value: props.zoneData?.zoneType ?? ZoneType.PROCESS,
        label: t('zoneType.' + props.zoneData?.zoneType ?? ZoneType.PROCESS),
      });
      setValue('zoneType', {
        value: props.zoneData?.zoneType ?? ZoneType.PROCESS,
        label: t('zoneType.' + props.zoneData?.zoneType ?? ZoneType.PROCESS),
      });
    }
  }, [props.zoneData]);

  function onSubmit() {
    setIsAddingLoading(true);

    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');
    let baseUrl = orgid + '/zone/add';
    if (props.mode == 'update') baseUrl = orgid + '/zone/edit/' + props.id;

    if (saveZone) {
      const dataToSend: ZoneSave = saveZone;

      dataToSend.zoneType = saveZone.zoneType ?? selectedOptionZoneType?.value ?? '';
      dataToSend.label = label ?? '';
      dataToSend.externalReference = externalReference;
      dataToSend.maxOccupation = maxOccupation ? maxOccupation : null;
      // dataToSend.products = props.zoneData?..map((p) => p.id);
      // dataToSend.childZonesId = props.zoneData?.childZones?.map((z) => z.id ?? '');
      dataToSend.isActive = isActive;

      axios
        .post(Config.getApiExtranetUrl(baseUrl), stringUtils.formatFieldsForPost(dataToSend), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setIsAddingLoading(false);
          addToast(t('common.zone' + (props.mode === 'update' ? 'Updated' : 'Created')), ToastTypes.success);
          props.onConfirm(res.data.content.uuid);
        })
        .catch((error) => {
          setIsAddingLoading(false);
          if (error.response) {
            if (
              error.response.data.code == 'ERR4010001' ||
              error.response.data.code == 'ERR4031001' ||
              error.response.data.code == 'ERR4010000'
            ) {
              history('/');
            }
          }
          addToast(
            (error?.response?.data?.code
              ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
              : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
            ToastTypes.error,
          );
        });
    } else {
      setIsAddingLoading(false);
    }
  }

  const handleShowTruckZones = () => {
    const orgid = localStorage.getItem('orgid');
    const url = 'truckZone';
    history(`/${url}/${props.zoneData?.id}/${orgid}/big`);
  };

  return !props.zoneData && props.mode === 'update' ? (
    <div>Loading...</div>
  ) : (
    <form id='form' onSubmit={handleSubmit(onSubmit)}>
      <CardDetail>
        <div className='form-section'>
          {props.mode === 'update' && (
            <FormRow align='right' alignVert='center'>
              <h3 className='base2'>{t('common.active')}</h3>
              <Switch
                disabled={true}
                onChange={function () {
                  setIsActive((prevValue) => !prevValue);
                }}
                checked={isActive ?? false}
                onColor={'#2a85ff'}
              />
            </FormRow>
          )}
          <FormRow>
            <Input
              error={errors.label?.message ?? ''}
              label={t('common.label')}
              type='text'
              {...register('label')}
              value={label ?? ''}
              onChange={(newValue: string | number) => {
                setLabel(newValue as string);
                setValue('label', newValue as string);
              }}
            />
            <Input
              error={errors.externalReference?.message ?? ''}
              label={t('common.externalReference')}
              type='text'
              {...register('externalReference')}
              value={externalReference ?? ''}
              onChange={(newValue: string | number) => {
                setExternalReference(newValue as string);
                setValue('externalReference', newValue as string);
              }}
            />
          </FormRow>
        </div>

        <div className='form-section'>
          <FormRow>
            <SelectZoneType
              titleSize='normal'
              register={register}
              registerName='zoneType'
              setValue={setValue}
              error={errors.zoneType?.value?.message ?? errors.zoneType?.label?.message ?? ''}
              isSelectable={true}
              selectedOptionChanged={(e: SelectZoneTypeOption) => {
                if (e && e.value) {
                  setValue('zoneType', {
                    value: e?.value,
                    label: e?.label,
                  });

                  setSaveZone((prevValue) => {
                    return { ...prevValue, zoneType: e?.value };
                  });
                } else {
                  setValue('zoneType', {
                    value: '',
                    label: '',
                  });

                  setSaveZone((prevValue) => {
                    return { ...prevValue, zoneType: undefined };
                  });
                }
              }}
              forceSelectedOption={selectedOptionZoneType}
            />
          </FormRow>
        </div>

        <div className='form-section'>
          <FormRow>
            <Input
              error={errors.currentOccupation?.message ?? ''}
              label={t('common.currentOccupation')}
              type='text'
              {...register('currentOccupation')}
              value={currentOccupation ?? ''}
              onChange={(newValue: string | number) => {
                console.log('currentOccupation is readonly');
              }}
              disabled={true}
            />
            <Input
              error={errors.maxOccupation?.message ?? ''}
              label={t('common.maxOccupation')}
              type='number'
              {...register('maxOccupation')}
              value={maxOccupation ?? ''}
              onChange={(newValue: string | number) => {
                setMaxOccupation(newValue as number);
                setValue('maxOccupation', newValue as number);
              }}
            />
          </FormRow>
          {props.mode === 'update' && (
            <FormRow align='right'>
              <Button
                label={t('common.showAreaDisplay')}
                size='medium'
                style={'primary'}
                iconLeft={'eye'}
                onClick={handleShowTruckZones}
              />
            </FormRow>
          )}
        </div>
        {/* <div className="form-section">
          <BuildDataCustom
            dataCustomType={'Zone'}
            dataCustom={zoneDataCustom}
            dataCustomUpdated={(data: DataCustomItem) => {
              setSaveZone((prevValue) => {
                return { ...prevValue, dataCustom: data };
              });
            }}
          />
        </div> */}
        <FixBar>
          <Button label={t('common.return')} style='white' onClick={props.onCancel} />
          <Button iconLeft='save' label={t('common.save')} style='primary' onClick={handleSubmit(onSubmit)} />
        </FixBar>
      </CardDetail>
    </form>
  );
});

GeneralZone.displayName = 'GeneralZone';
export default GeneralZone;
